import { getRequest, postRequest, BaseRequestParams, PostRequestMethodArgs, RequestMethodArgs } from "kdg-react"
import { AUTH_TOKEN_KEY, tryParseAuthFromToken, tryParseRefreshFromToken } from "../Context/AuthContext"
import { handleLogout, refreshAuthToken } from "./auth"

const GET_DEFAULT_HEADERS = () => ({
  Authorization: 'Bearer ' + window.localStorage.getItem(AUTH_TOKEN_KEY) || '',
})

export const tryRefreshToken = async (successCallback: () => Promise<void>) => {
  const token = tryParseAuthFromToken(window.localStorage.getItem(AUTH_TOKEN_KEY) || '')
  await refreshAuthToken({
    body: {
      refresh_token: tryParseRefreshFromToken(token.auth?.token || ''),
      email: token.auth?.user.email || '',
      alias: token.auth?.user.alias || null,
    },
    success: (token) => {
      window.localStorage.setItem(AUTH_TOKEN_KEY, token)
      successCallback()
    },
    errorHandler: () => handleLogout()
  })
}

type GetParams<T> = BaseRequestParams<T> & RequestMethodArgs<T>
export const httpGet = async <T extends {}>(args: GetParams<T>) => {
  const request = async () =>
    await getRequest({
      url: args.url,
      parameters:args.parameters,
      headers: {
        ...GET_DEFAULT_HEADERS(),
        ...(args.headers || {})
      },
      mapResult: args.mapResult,
      success: args.success,
      errorHandler: resp => {
        if (resp.status === 401) {
          tryRefreshToken(request)
        } else {
          if (args.errorHandler) args.errorHandler(resp)
        }
      }
    })
  await request()
}
type PostParams<T, R> = BaseRequestParams<R> & PostRequestMethodArgs<T,R>
export const httpPost = async <T extends {}, R extends {}>(args: PostParams<T, R>) => {
  const request = async () =>
    await postRequest({
      url: args.url,
      parameters: args.parameters,
      body: args.body,
      headers: {
        ...GET_DEFAULT_HEADERS(),
        ...(args.headers || {})
      },
      mapResult: args.mapResult,
      success: args.success,
      errorHandler: resp => {
        if (resp.status === 401) {
          tryRefreshToken(request)
        } else {
          if (args.errorHandler) args.errorHandler(resp)
        }
      }
    })
  await request()
}

export const downloadFile = async (path:string,name:string) => {
  var blob = await fetch(
    path,{
      headers:GET_DEFAULT_HEADERS()
    }
  )
  if(blob.ok)
  {
    const t = await blob.blob()
    const url = window.URL.createObjectURL(t);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove()
  }
  else
  {
    throw new Error("test")
  }
}