import { useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import { TPagination } from 'kdg-react';



export const useAppNavigation = () => useNavigate()

export const useAppNavigationQueryParams = () => {
  const params = useSearchParams()
  return params
}

export type PaginatorClass = {
  pagination:TPagination
  setPage:(_:number) => void
  setPerPage : (_:number) => void
  decrement: () => void
  increment: () => void
  reset:() => void
  update:(v:TPagination) => void
}

export const usePagination = (defaultPaginator:TPagination) : PaginatorClass => {
  const [pagination,setPagination] = useState<TPagination>(defaultPaginator)

  const setPage = (page:number) => setPagination(prev => ({
    ...prev,
    page,
  }))

  const setPerPage = (per_page:number) => setPagination(prev => ({
    ...prev,
    per_page,
    page:1,
  }))

  const decrement = () => setPagination(prev => ({
    ...prev,
    page:prev.page - 1,
  }))

  const increment = () => setPagination(prev => ({
    ...prev,
    page:prev.page + 1,
  }))

  const reset = () => setPagination(defaultPaginator)

  const update = (v:TPagination) => {
    if(v.page != pagination.page || v.numberOfItemsPerPage != pagination.numberOfItemsPerPage){
      setPagination(v)
    }
  }

  return {
    pagination,
    setPage,
    setPerPage,
    decrement,
    increment,
    reset,
    update,
  }
}

