import { contextBuilder, contextDispatchBuilder, ContextProvider } from "kdg-react"
import { PropsWithChildren } from "react"
import { User } from "../Types/user"

export type AuthContextType = {
  auth: {
    user: User
    token: string
  } | null
}

export const AUTH_TOKEN_KEY = 'auth_token_key'
export const USER_ALIAS = 'user_alias'

export const tryParseRefreshFromToken = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1])).refresh_token
  } catch {
    return null
  }
}

export const tryParseAuthFromToken = (token: string): AuthContextType => {
  try {
    // parse user from token
    const parsedUser = JSON.parse(JSON.parse(atob(token.split('.')[1])).user)
    return {
      auth: {
        token,
        user: {
          id: parsedUser.Id,
          email: parsedUser.Email,
          firstName: parsedUser.FirstName || null,
          lastName: parsedUser.LastName || null,
          alias: parsedUser.Alias || null,
          permissionGroup: parsedUser.PermissionGroup,
          permissions: parsedUser.Permissions,
          status:!!parsedUser.inactive,
        },
      },
    }
  } catch (e) {
    console.error('unable to parse user from token', e)
    return {
      auth:null,
    }
  }
}

export const defaultAuthContext: AuthContextType =
  !!window.localStorage.getItem(AUTH_TOKEN_KEY)
    ? tryParseAuthFromToken(window.localStorage.getItem(AUTH_TOKEN_KEY)!)
    : { auth:null }

export const AuthContext = contextBuilder<AuthContextType>(defaultAuthContext)
export const AuthContextDispatcher = contextDispatchBuilder<AuthContextType>()
export const AuthContextProvider = (props: PropsWithChildren<AuthContextType>) => (
  <ContextProvider
    context={AuthContext}
    contextDispatcher={AuthContextDispatcher}
    defaultContext={props}
  >
    {props.children}
  </ContextProvider>
)