import { httpGet } from "./_common"
import { RequestMethodArgs, TPagination } from "kdg-react"
import { DepartmentMeta,Responses,Department } from '../Types/department'
import { PaginatedResults } from '../Types/pagination'
import { Option } from '../Types/util'
import { TSortFields } from '../Hooks/sort'

export const fetchDepartments = async (paginator: TPagination, search:string,jobId:Option<string>, sort:TSortFields, args: RequestMethodArgs<PaginatedResults<DepartmentMeta>>) => {
    await httpGet({
      url: '/api/departments',
      success: args.success,
      errorHandler: args.errorHandler,
      mapResult: (b: unknown) => {
        var a  = b as PaginatedResults<Responses.DepartmentMeta>
        return ({
          count: a.count,
          results: a.results
        })
    },
      parameters: {
        Limit: paginator.numberOfItemsPerPage.toString(),
        Offset: (paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
        Search: search,
        JobId:jobId ? jobId : '',
        sortField:sort.fieldName,
        sortDir:sort.order,
      },
    });
};

export const fetchDepartment = async (id:string, args: RequestMethodArgs<Department>) => {
    await httpGet({
        url: `/api/departments/${id}`,
        success: args.success,
        errorHandler: args.errorHandler,
        mapResult: (b: unknown) => {
            return b as Responses.Department
        }
    })
}

export const syncNow = async (args:RequestMethodArgs<string>) => {
    await httpGet({
        ...args,
        url:`api/departments/sync-now`,
    })
}