import { PropsWithChildren,useContext } from "react"
import { NavBar,Sidebar } from 'kdg-react'

import Icon, { ICON_KIND } from './Icon'

import { AuthContext } from '../Context/AuthContext'
import { useAppNavigation } from "../Hooks/hooks"
import { ROUTE_PATH } from "../Routing/Routing"
import { handleLogout } from "../Api/auth"
import { Permission } from '../Types/permission'
import { hasPermission } from '../Components/PermissionHandler'

import whiteLogo from '../assets/img/logo-white.png'

type Props = {
  pageTitle:string
}

const BaseLayout = (props:PropsWithChildren<Props>) => {
  const navigate = useAppNavigation()
  const auth = useContext(AuthContext)

  const sidebarRoutes = [
    { items:[{ key:ROUTE_PATH.DASHBOARD,label:'Dashboard', onClick:() => { navigate(ROUTE_PATH.DASHBOARD) }, icon:<Icon kind={ICON_KIND.View} /> }] },
    { permission:Permission.VIEW_BILLS, title:{ subTitle: 'SYNC', title:'Sync Modules' }, items:[{ key:ROUTE_PATH.BILLS,label:'Bills', onClick:() => { navigate(ROUTE_PATH.BILLS) }, icon:<Icon kind={ ICON_KIND.Money } /> }] },
    { permission:Permission.VIEW_DEPARTMENTS, items: [{ key:ROUTE_PATH.DEPARTMENTS,label:'Departments', onClick:() => { navigate(ROUTE_PATH.DEPARTMENTS) }, icon:<Icon kind={ ICON_KIND.Institution } /> }] },
    { permission:Permission.VIEW_LOCATIONS, items:[{ key:ROUTE_PATH.LOCATIONS,label:'Locations', onClick:() => { navigate(ROUTE_PATH.LOCATIONS) }, icon:<Icon kind={ ICON_KIND.AddItem } /> }] },
    { permission:Permission.VIEW_PROJECTS, items:[{ key:ROUTE_PATH.PROJECTS,label:'Projects', onClick:() => { navigate(ROUTE_PATH.PROJECTS) }, icon:<Icon kind={ ICON_KIND.Doc } /> }] },
    { permission:Permission.VIEW_VENDORS, items:[{ key:ROUTE_PATH.VENDORS,label:'Vendors', onClick:() => { navigate(ROUTE_PATH.VENDORS) }, icon:<Icon kind={ ICON_KIND.Handshake } /> }] },
    { title:{ subTitle: 'MGMT', title:'Module Management' }, items: [{ key:ROUTE_PATH.ALERT_EMAILS, label:'Alert Emails', onClick:() => { navigate(ROUTE_PATH.ALERT_EMAILS) }, icon:<Icon kind={ ICON_KIND.Email } /> }] },
    { items: [{ key:ROUTE_PATH.DATA_DICTIONARY,label:'Data Dictionary', onClick:() => { navigate(ROUTE_PATH.DATA_DICTIONARY) }, icon:<Icon kind={ ICON_KIND.SpreadSheet } /> }] },
    { permission:Permission.CREATE_USERS, items:[{ key:ROUTE_PATH.SCHEDULED_TASKS,label:'Hangfire', onClick:() => { navigate(ROUTE_PATH.SCHEDULED_TASKS) }, icon:<Icon kind={ ICON_KIND.Fire } /> }] },
    { permission:Permission.CREATE_USERS, items:[{ key:ROUTE_PATH.USER_MANAGEMENT,label:'User Management', onClick:() => { navigate(ROUTE_PATH.USER_MANAGEMENT) }, icon:<Icon kind={ ICON_KIND.Group } /> }] },


    //{ permission:Permission.VIEW_EMPLOYEES, items:[{ key:ROUTE_PATH.EMPLOYEES,label:'Employees', onClick:() => { navigate(ROUTE_PATH.EMPLOYEES) }, icon:<Icon kind={ ICON_KIND.Group } /> }] },
  ].filter((v) => auth.auth && v.permission ? hasPermission(auth.auth.user, v.permission) : true)

  return (
    <div>
      <Sidebar
        active={
          (url:string) => url
        }
        brand={
          <div className="sidebar-brand d-none d-md-flex">
            <img className="sidebar-brand-full" src={ whiteLogo } width="80px" alt="SMSI Logo"></img>
            <img className="sidebar-brand-narrow" src={ whiteLogo } width="58px" alt="SMSI Logo"></img>
          </div>
        }
        sections={ sidebarRoutes }
      />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <header className="header header-sticky p-0">
          <NavBar
            brand={ { content: props.pageTitle } }
            fluid={ true }
            navClassName={ 'justify-content-end' }
            items={
              [
                { key:'options',label:<Icon kind={ ICON_KIND.Gear }/>,items:[{ key:'logout',label:'Logout',onClick:() => { handleLogout() } }] }
              ]
            }
          />
        </header>
        <div className="body flex-grow-1 px-3 d-flex flex-column">
            {props.children}
        </div>
      </div>
    </div>
  )
}

export default BaseLayout