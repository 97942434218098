import { Fragment,useState,useEffect,useCallback } from 'react'
import { Card, DiscriminatedUnionHandler,Loader, Conditional,TPagination, AsyncButton,Col,} from 'kdg-react'
import Icon, { ICON_KIND } from './Icon'

import { getJobs,FetchJobKind } from '../Api/jobs'
import { defaultLoadedData,TLoadedCase, TLoadedData } from '../Types/util'
import { Job,JobKinds } from '../Types/job'
import { getDirections } from '../Types/sync'
import List from './List'
import SyncStats from './SyncStats'

import { formatInstant } from '../Util/datetime'

type State = {
  jobs: Job[]
  success: number
  failed:number
}

const Counts = (props:{ amount:number, label:string}) => {
  return (
    <Conditional
      condition={ props.amount > 0 }
      onTrue={ () => <p className="small text-muted mb-0">{ props.amount } { props.label }</p> }
    />
  )
}

type Props = {
  jobs:JobKinds[]
  kind:FetchJobKind
  onItemSelect:(job:Job) => void
}

const Sync = (props:Props) => {
  const [jobs, setJobs] = useState<TLoadedData<State>>(defaultLoadedData)
  const [paginator,setPaginator] = useState<TPagination>({ numberOfItemsPerPage:8,page:1 })
  const [previous,setPrevious] = useState<Job[]>([])
  const [total,setTotal] = useState(0)
  const [loading,setLoading] = useState(false)

  const loadJobs = useCallback(async () => {
    setLoading(true)
    await getJobs(props.jobs,props.kind,paginator,{
        success: (v) => {
          const all = previous.concat(v.paginationData.results)
          setPrevious(all)
          setTotal(v.paginationData.count)
          setJobs({
            case: TLoadedCase.LOADED,
            value: {
              jobs: all,
              success: v.results.success,
              failed:v.results.failed,
            },
          })
          setLoading(false)
        },
        errorHandler:() => setLoading(false)
      })
  },[paginator])
  const handleLoadVendor = useCallback(async () => {
    await getJobs(props.jobs,props.kind,paginator,{
      success: (v) => {
        const all = previous.concat(v.paginationData.results)
        setPrevious(all)
        setTotal(v.paginationData.count)
        setJobs({
          case: TLoadedCase.LOADED,
          value: {
            jobs: all,
            success: v.results.success,
            failed:v.results.failed,
          },
        })
      }
    })
    setJobs(defaultLoadedData)
  }, [paginator])

  useEffect(() => { handleLoadVendor() },[])

  useEffect(() => {
    loadJobs()
  }, [loadJobs])
  return (
    <Fragment>
      <DiscriminatedUnionHandler
        value={jobs}
        config={{
          [ TLoadedCase.LOADING ]: () => <Loader />,
          [ TLoadedCase.ERROR ]: e => e,
          [ TLoadedCase.LOADED ]: ({ value:state }) => (
            <Fragment>
              <div className='row ps-2 pe-2'>
                <Col className='m-0 p-0 ps-1 pe-1' xl={ 6 } >
                  <SyncStats
                    amount={state.success}
                    kind={ 'success' }
                  />
                </Col>
                <Col className='m-0 p-0 ps-1 pe-1 ' xl={ 6 } >
                  <SyncStats
                    amount={state.failed}
                    kind={'error'}
                  />
                </Col>
              </div>
              <Card
                className='mb-3'
                body={{
                  className: '',
                  content:
                    <div>
                      <List
                        items={state.jobs}
                        className={'history-log'}
                        itemClass={'clickable'}
                        extractKey={(v) => v.id}
                        onItemClick={
                          (v) => {
                           props.onItemSelect(v)
                          }
                        }
                        renderItem={(v) => {
                          const direction = getDirections(v.name)
                          return (
                            <Fragment>
                              <div
                                className={ `d-flex justify-content-between align-items-center mb-1 ${ v.errors ? 'fail-sync' : 'success-sync' }` }
                              >
                                <p className="pe-3">{ direction.from } <Icon kind={ ICON_KIND.ArrowRight } /> { direction.to }{direction.entity && <span className='text-muted'>: {direction.entity}</span> }</p>
                                <p className="timestamp text-muted small">{ formatInstant(v.start) }</p>
                              </div>
                              <div className="ps-3 border-start">
                                <Counts amount={ v.created } label={ 'additions' } />
                                <Counts amount={ v.updated } label={ 'updates' } />
                                <Counts amount={ v.errors } label={ 'errors' } />
                                <Conditional
                                  condition={ !v.created && !v.updated && !v.errors }
                                  onTrue={
                                    () => <p className="small text-muted mb-0">No changes</p>
                                  }
                                />
                              </div>
                            </Fragment>
                          )
                        } }
                      />
                      <Conditional
                        condition={ total > state.jobs.length }
                        onTrue={
                          () => (
                            <div className='d-flex justify-content-center py-2'>
                              <AsyncButton loading={ loading } disabled={ loading } onClick={() => { setPaginator({ ...paginator,page: paginator.page + 1 })}} className='btn-sm'>View Older</AsyncButton>
                            </div>
                          )
                        }
                      />
                    </div>
                }} />
            </Fragment>
          )
        }}
      />
    </Fragment>
  )
}
export default Sync
