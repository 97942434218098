import { ReactNode } from 'react'

type Props<T> = {
    label:ReactNode
    value:T[]
    options:T[]
    onChange:(_:T[]) => void
    className?:string
    parseOptionLabel:(_:T) => ReactNode
}
const Checkboxes = <T,>(props:Props<T>) => {
    const update = (v:T) => {
        props.onChange(
            props.value.filter((x) => x == v).length ?
                props.value.filter((x) => x != v)
            :
                props.value.concat([v])
        )
    }
    return (
        <div className={ props.className }>
            <h5>{ props.label }</h5>
            {
                props.options.map((v,i) => {
                    return (
                        <div key={ `index-${i}` } onClick={ () => update(v) } className={ `bubble-checkbox ${props.value.filter((x) => x == v).length ? 'box-selected' : ''}` }>
                            <label>{ props.parseOptionLabel(v) }</label>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Checkboxes