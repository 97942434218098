import { Fragment,PropsWithChildren } from 'react'
import { Card } from 'kdg-react'

type Props = {
  title:string
}
const DetailSection = (props:PropsWithChildren<Props>) => {
  return (
    <Fragment>
      <h6 className={ 'mt-4' }>{ props.title }</h6>
      <Card
        body={
          {
            content:props.children
          }
        }
      />
    </Fragment>
  )
}

export default DetailSection
