import { Select,TextInput,Checkbox,Col,Clickable,Row,AsyncButton,TError} from 'kdg-react'

import { TAlertEmailForm,AlertEmailEmail } from '../../../Types/alertEmail'
import { JobKinds } from '../../../Types/job'
import Icon, { ICON_KIND } from '../../../Components/Icon'

type Props = {
    alertEmail:TAlertEmailForm
    onChange:(_:TAlertEmailForm) => void
    save:() => void
    errors:TError<any>
    onCancel:() => void
    saving:boolean
}

const AlertEmailForm = (props:Props) => {
    const replaceEmail = (email:AlertEmailEmail,index:number) => {
        props.onChange({
            ...props.alertEmail,
            emails:props.alertEmail.emails.map((v,i) => i == index ? email : v)
        })
    }
    return (
        <div>
            <Row>
                <Col className="mt-0" md={6}>
                    <Select
                        label={ 'Hangfire Job' }
                        placeholder='Select a Hangfire Job'
                        parseKey={ (v) => v }
                        parseOptionLabel={ (v) => v }
                        options={
                            Object.values(JobKinds).filter((item) => {
                                return isNaN(Number(item));
                            })
                        }
                        value={ props.alertEmail.job }
                        onChange={ (v) => props.onChange({ ...props.alertEmail,job:v }) }
                        error={ props.errors.job }
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mt-4" md={6}>
                    <TextInput
                        value={ props.alertEmail.name }
                        onChange={ (v) => props.onChange({ ...props.alertEmail,name:v }) }
                        label={ 'Email Name' }
                        placeholder='What would you like to call this email?'
                        error={ props.errors.name}
                    />
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col className="mt-0" md={12}>
                    <h4>Email Recipients</h4>
                    <p className='helper-text'>Add users to receive emails and select which type of email you want this user to receive.</p>
                    <div className={ 'd-flex mt-3' }>
                        <Col md={ 2 }></Col>
                        <Col md={ 6 }></Col>
                        <Col md={ 2 }><p className='text-center'>Success email</p></Col>
                        <Col md={ 2 }><p className='text-center'>Error email</p></Col>
                    </div>
                    <div>
                        {
                            props.alertEmail.emails.map((email,index) => (
                                <Row className={ 'd-flex align-items-center justify-content-center mb-4' }>
                                    <Col md={ 2 }>
                                        <Clickable onClick={ () => props.onChange({ ...props.alertEmail,emails:props.alertEmail.emails.filter((_,i) => i !== index )}) } > - Remove</Clickable>
                                    </Col>
                                    <Col md={ 6 }>
                                        <TextInput
                                            value={ email.email }
                                            onChange={ (v) => replaceEmail({ ...email,email:v },index) }
                                            error={ props.errors[`Emails[${index}].Email`] }
                                        />
                                    </Col>
                                    <Col md={ 2 } className={ 'd-flex form-check justify-content-center' }>
                                        <Checkbox
                                            value={ email.onSuccess }
                                            onChange={ (v) => replaceEmail({ ...email,onSuccess:v },index) }
                                            error={ props.errors[`Emails[${index}].OnSuccess`] }
                                        />
                                    </Col>
                                    <Col md={ 2 } className={ 'd-flex form-check justify-content-center' }>
                                        <Checkbox
                                            value={ email.onFailure }
                                            onChange={ (v) => replaceEmail({ ...email,onFailure:v },index) }
                                            error={ props.errors[`Emails[${index}].OnFailure`] }
                                        />
                                    </Col>
                                </Row>
                            ))
                        }
                        <div>
                            <Clickable onClick={ () => props.onChange({ ...props.alertEmail, emails:props.alertEmail.emails.concat({ id:'',email:'',onFailure:false,onSuccess:false }) }) }><Icon kind={ ICON_KIND.Add } /> Add Recipient</Clickable>
                        </div>
                    </div>
                </Col>
            </Row>
            <hr className='mt-5'></hr>
            <div className={ 'd-flex justify-content-end' }>
                <Clickable onClick={ () => props.onCancel() } className={ 'align-items-center d-flex mx-2' }>Cancel</Clickable>
                <AsyncButton loading={ props.saving } onClick={ () => props.save() } >Save</AsyncButton>
            </div>
        </div>
    )
}

export default AlertEmailForm