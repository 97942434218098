import { useState,useEffect } from 'react'
import { DiscriminatedUnionHandler,Loader,Card,Row,DelayedTextInput,Pagination,SortOrder } from 'kdg-react'

import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { paginationOptions } from '../../Types/pagination'
import { ProjectMeta,SortableFields } from '../../Types/projects'

import Icon, { ICON_KIND } from '../../Components/Icon'
import { getProjects } from '../../Api/projects'
import BaseLayout from '../../Components/BaseLayout'
import { useAppNavigation, usePagination } from "../../Hooks/hooks"
import { useTableSort,defaultSort } from '../../Hooks/sort'
import Table from '../../Components/TableWithNoData'

const initialSort = {
    field:SortableFields.ProjectName,
    sort:defaultSort,
    sortOrder:SortOrder.ASC,
}

const Projects = () => {
  const [projects, setProjects] = useState<TLoadedData<ProjectMeta[]>>(defaultLoadedData)
  const [total,setTotal] = useState(0)
  const [search,setSearch] = useState("")
  const paginator = usePagination({ numberOfItemsPerPage:10,page:1 })
  const navigate = useAppNavigation()

    const { tableSort, setTableSort,mappedSortFields } = useTableSort<SortableFields,ProjectMeta>({
        mapping:{
            [SortableFields.ProjectName]:'project_name',
            [SortableFields.ProjectNumber]:'project_number',
            [SortableFields.Status]:'status',
        },
        initialState:initialSort,
    })

    const parseTableField = (x:string):SortableFields => {
        switch (x) {
            case SortableFields.ProjectName:
            case SortableFields.ProjectNumber:
            case SortableFields.Status:
            return x
          default:
            throw new Error(`unable to parse table field: ${x}`)
        }
      }

  const fetch = async () => {
    setProjects(defaultLoadedData)
    getProjects(paginator.pagination,search,mappedSortFields,{
      success:(v) => {
        setProjects({ case:TLoadedCase.LOADED,value:v.results })
        setTotal(v.count)
      },
      errorHandler:(error) => {
        setProjects({ case:TLoadedCase.ERROR,value:'cannot load projects' })
        console.error('error',error)
      }
    } )
  }

  useEffect(() => {
    fetch()
  },[paginator.pagination,search,mappedSortFields.fieldName,mappedSortFields.order])

  return (
    <BaseLayout
      pageTitle={ 'Projects' }
    >
      <Row fluid>
          <Card
            header={ {
              className:'d-flex align-items-center',
              content:
                <div className=" d-flex align-items-center justify-content-start">
                  <div className="d-flex align-items-center me-3">
                    <DelayedTextInput
                      placeholder="Search..."
                      value={ search }
                      delay={ 300 }
                      onDelay={
                        (v) => {
                          setSearch(v || "")
                          paginator.reset()
                        }
                      }
                    />
                  </div>
                </div>
            } }
            body={ {
              content:<DiscriminatedUnionHandler
                value={ projects }
                config={ {
                  [TLoadedCase.LOADING]: () => <Loader />,
                  [TLoadedCase.ERROR]: e => e.value,
                  [TLoadedCase.LOADED]:(v) => (
                    <Table
                      noDataMessage={ 'There are no projects to display.' }
                      footer={
                        <Pagination pagination={ paginator.pagination } totalRecords={ total } onChange={ paginator.update } pageOptions={ paginationOptions } optionsDirection='top'/>
                      }
                      headerClassName='table-light'
                      className={ 'table-hover row-border' }
                      items={ v.value }
                      onSort={
                        (v) => setTableSort({
                            field:parseTableField(v.field.toString()),
                            sort:v.sort.sort,
                            sortOrder:v.sort.order,
                        })
                        }
                        defaultSort={
                            {
                                field:tableSort.field,
                                sort:{
                                    sort:tableSort.sort,
                                    order:tableSort.sortOrder,
                                }
                            }
                        }
                        itemKey={ (v) => v.id }
                      fields={
                        {
                          [SortableFields.ProjectName]:{ valueRender:(v) => v.projectName,sort:{ sort:defaultSort,order:SortOrder.ASC } },
                          [SortableFields.ProjectNumber]: { valueRender:(v) => v.projectNumber,sort:{ sort:defaultSort,order:SortOrder.ASC } },
                          [SortableFields.Status]: { valueRender:(v) => v.status,sort:{ sort:defaultSort,order:SortOrder.ASC } },
                          'link':{ hideHeader:true,valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/projects/${v.id}`) }/> },
                        }
                      }
                    />
                  )
                } }
              />
            } }
          />
      </Row>
    </BaseLayout>
  )
}

export default Projects