import { RecordActions } from './job';
import { Option,RelatedItems } from './util';
import { LocalDate } from '@js-joda/core';

export enum SortableFields {
    INVOICE_NUMBER='Invoice Number',
    DUE_DATE='Due Date',
    TRANSACTION_DATE='Transaction Date',
}

export interface BillMeta {
  id:string
  invoiceNumber: Option<string>
  transactionDate: Option<LocalDate>
  dueDate: Option<LocalDate>
  status:RecordActions
};

export interface LineItem {
  id:string
  account: Option<RelatedItems>
  item: Option<RelatedItems>
  location: Option<RelatedItems>
  total:Option<number>
};

export interface Bill extends BillMeta {
  lineItems:LineItem[]
}


export namespace Responses {
  export interface BillMeta {
    id:string
    invoiceNumber: Option<string>
    transactionDate: Option<string>
    dueDate: Option<string>
    status:RecordActions
  };
  export interface Bill {
    id:string
    invoiceNumber: Option<string>
    transactionDate: Option<string>
    dueDate: Option<string>
    status:RecordActions
    lineItems: LineItem[]
  };
}


