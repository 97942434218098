import React from 'react'

import { Card } from 'kdg-react'

interface SectionProps {
  children:React.ReactNode
  title:React.ReactNode
}

const Section: React.FC<SectionProps> = ({ children,title }) => {
  return (
    <>
        <h6 className="mt-4">{ title }</h6>
        <Card
            body={ {
                content:children
            } }
        />
    </>
  );
};

export default Section;
