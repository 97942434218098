import { tryParseErrors, TError } from 'kdg-react'

import { TAlertEmailForm } from '../../../Types/alertEmail'

type ServerError = {
    propertyName:string
    errorMessage:string
}


export const parseErrors = async (form:TAlertEmailForm,httpResponse:Response) : Promise<TError<TAlertEmailForm>> => {
    const t = await httpResponse.json()
    const parsers : TError<any> = {
        job: 'Job',
        name:'Name',
    }
    form.emails.forEach((_,i) => {
        const key = `Emails[${i}].`
        parsers[`${key}Email`] = `${key}Email`
        parsers[`${key}OnFailure`] = `${key}OnFailure`
        parsers[`${key}OnSuccess`] = `${key}OnSuccess`
    })
    const e = await tryParseErrors<TAlertEmailForm>({
        response:{ case:'ParsedResponse',value:t },
        parsers:parsers,
        parseMaps:[(body,field) => {
            console.log('field',field,body)
            const errs = body.value.filter((v:ServerError) => v.propertyName == field)
            return errs.length ? errs[0].errorMessage : undefined
        }],
    })
    return e
}