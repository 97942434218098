import { LocalDate } from '@js-joda/core'
import { RequestMethodArgs,TPagination } from "kdg-react"

import { BillMeta,Responses,Bill } from '../Types/bills'
import { httpGet, httpPost } from "./_common"
import { PaginatedResults } from '../Types/pagination'
import { tryParseDate } from "../Util/datetime"
import { Option } from '../Types/util'
import { SyncStatuses } from '../Components/Form/SyncStatusCheckboxes'
import { TSortFields } from '../Hooks/sort'

const mapBill = (b: Responses.BillMeta): BillMeta => ({
  id: b.id,
  invoiceNumber: b.invoiceNumber || null,
  transactionDate: b.transactionDate ? tryParseDate(b.transactionDate) : null,
  dueDate: b.dueDate ? tryParseDate(b.dueDate) : null,
  status: b.status || null,
});

const mapBillFull = (b: Responses.Bill): Bill => ({
  ...mapBill(b),
  lineItems: b.lineItems.map(li => ({
    id: li.id,
    account: li.account || null,
    item: li.item || null,
    location: li.location || null,
    total:li.total,
  })),
});


export const getBills = async (paginator: TPagination, search:string, job:Option<string>, status:SyncStatuses[],start:Option<LocalDate>,end:Option<LocalDate>,sort:TSortFields,args: RequestMethodArgs<PaginatedResults<BillMeta>>) => {
  await httpPost({
    url: '/api/bills',
    success: args.success,
    errorHandler: args.errorHandler,
    mapResult: (b: unknown) => {
      var a  = b as PaginatedResults<Responses.BillMeta>
      return ({
        count: a.count,
        results: a.results.map(mapBill)
      })
  },
    body: {
      Limit: paginator.numberOfItemsPerPage.toString(),
      Offset: (paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
      Search: search,
      Job:job ? job : '',
      status:status,
      start,
      end,
      sortField:sort.fieldName,
      sortDir:sort.order,
    },
  });
};


export const fetchBill = async (id: string, args: RequestMethodArgs<Bill>) => {
  await httpGet({
    url: `/api/bills/${id}`,
    success: args.success,
    errorHandler: args.errorHandler,
    mapResult: (b: unknown) => {
      var a = b as Responses.Bill;
      return mapBillFull(a);
    },
  });
};

export const syncNow = async (args:RequestMethodArgs<Bill>) => {
    await httpGet({
        ...args,
        url:`api/bills/sync-now`
    })
}

