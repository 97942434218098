import { useEffect,useState,useCallback } from 'react'
import { Row,Col,Card,DelayedTextInput,Loader,DiscriminatedUnionHandler,Pagination,EntityConditional,useNotification,Enums,TPagination,RequestMethodArgs,SortOrder } from 'kdg-react'

import { defaultLoadedData, TLoadedCase, TLoadedData,Option } from '../../Types/util'
import BaseLayout from '../../Components/BaseLayout'
import Icon, { ICON_KIND } from '../../Components/Icon'
import { useAppNavigation,usePagination } from "../../Hooks/hooks"
import { fetchDepartments,syncNow } from '../../Api/departments'
import { DepartmentMeta,SortableFields } from '../../Types/department'
import Table from '../../Components/TableWithNoData'
import { paginationOptions,PaginatedResults } from '../../Types/pagination'
import Sync from '../../Components/Sync'
import { JobKinds,Job, Entities, RecordActions,} from '../../Types/job'
import { FetchJobKind } from '../../Api/jobs'
import JobDetails from '../../Components/JobDetails'
import SyncButton from '../../Components/Buttons/Sync'
import ModuleSyncDirection from '../../Components/ModuleSyncDirection'
import { CreatorToIntacctAndCRM } from '../../Types/sync'
import { ToUpperFirst } from '../../Util/string'
import { useTableSort,defaultSort } from '../../Hooks/sort'

const initialSort = {
    field:SortableFields.Name,
    sort:defaultSort,
    sortOrder:SortOrder.ASC,
}

const Departments = () => {
    const navigate = useAppNavigation()
    const [job, setJob] = useState<Option<Job>>(null)
    const paginator = usePagination({ numberOfItemsPerPage:10,page:1 })
    const [departments, setDepartments] = useState<TLoadedData<DepartmentMeta[]>>(defaultLoadedData)
    const [total,setTotal] = useState(0)
    const [search,setSearch] = useState('')
    const { notify } = useNotification()

    const { tableSort, setTableSort,mappedSortFields } = useTableSort<SortableFields,DepartmentMeta>({
        mapping:{
            [SortableFields.Name]:'name',
        },
        initialState:initialSort,
    })

    const parseTableField = (x:string):SortableFields => {
        switch (x) {
            case SortableFields.Name:
            return x
          default:
            throw new Error(`unable to parse table field: ${x}`)
        }
      }

    const fetch = useCallback(async () => {
        setDepartments(defaultLoadedData)
        await fetchDepartments(paginator.pagination,search,null,mappedSortFields,{
            success:(x) => {
                setDepartments({ case:TLoadedCase.LOADED, value:x.results })
                setTotal(x.count)
            },
            errorHandler:() => {
                setDepartments({ case:TLoadedCase.ERROR,value:'cannot load departments' })
                setTotal(0)
            }
        })
    },[paginator.pagination.numberOfItemsPerPage,paginator.pagination.page,search,mappedSortFields.fieldName,mappedSortFields.order])

    const jobFetch = async (job:string,pagination:TPagination,args:RequestMethodArgs<PaginatedResults<DepartmentMeta>>) => {
        fetchDepartments(pagination,'',job,{ fieldName:'name',order:initialSort.sortOrder },args)
    }

    const triggerSync = async () => {
        syncNow({
            success:(_) => {
                notify({
                    key:new Date().getUTCMilliseconds(),
                    message:'Sync Successfully Triggered',
                    type:Enums.Color.Success,
                    autoDismiss:3000
                })
            }
        })
    }

    useEffect(() => { fetch() }, [fetch])

    return (
        <BaseLayout pageTitle={ 'Departments' }>
            <Row fluid>
                <Col md={ 8 } lg={ 9 }>
                    <Card
                        header={ {
                        className:'d-flex align-items-center',
                        content:
                            <div className="d-flex justify-content-between w-100">
                                <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                        <DelayedTextInput
                                            placeholder="Search..."
                                            value={ search }
                                            delay={ 300 }
                                            onDelay={
                                                (v) => {
                                                    setSearch(v || "")
                                                    paginator.reset()
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <SyncButton onClick={ triggerSync } />
                            </div>

                        } }
                        body={ {
                        content:<DiscriminatedUnionHandler
                            value={ departments }
                            config={ {
                            [TLoadedCase.LOADING]: () => <Loader />,
                            [TLoadedCase.ERROR]: e => e,
                            [TLoadedCase.LOADED]:({ value:v }) => (
                                <Table
                                    noDataMessage={ 'There are no bills to display.' }
                                    footer={
                                        <Pagination pagination={ paginator.pagination } totalRecords={ total } pageOptions={ paginationOptions } onChange={ paginator.update } optionsDirection='top' />
                                    }
                                    headerClassName='table-light'
                                    className={ 'table-hover row-border mb-0' }
                                    items={ v }
                                    itemKey={ (v) => v.id }
                                    extractRowClassname={ (v) => v.kind == RecordActions.ERROR ? 'error' : 'success' }
                                    onSort={
                                        (v) => setTableSort({
                                            field:parseTableField(v.field.toString()),
                                            sort:v.sort.sort,
                                            sortOrder:v.sort.order,
                                        })
                                    }
                                    defaultSort={
                                        tableSort ? {
                                            field:tableSort.field,
                                            sort:{
                                                sort:tableSort.sort,
                                                order:tableSort.sortOrder,
                                            }
                                        } : undefined
                                    }
                                    fields={
                                        {
                                            [SortableFields.Name]:{ valueRender:(v) => v.name,sort:{ sort:defaultSort,order:SortOrder.ASC } },
                                            'link':{
                                                hideHeader:true,
                                                valueRender:(v) =>
                                                    <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/departments/${v.id}`) }/>
                                                },
                                        }
                                    }
                                />
                            )
                            } }
                        />
                        } }
                    />
                </Col>
                <Col md={4} lg={3}>
                <Card
                    className='mb-2'
                    body={{
                    className: '',
                    content:
                    <ModuleSyncDirection  direction={ CreatorToIntacctAndCRM } entity={ Entities.SMSI }/>
                    }}
                />
                    <Sync
                        jobs={ [JobKinds.PULL_DEPARTMENTS,JobKinds.SEND_DEPARTMENTS_CRM,JobKinds.SEND_DEPARTMENTS_INTACCT] }
                        kind={ FetchJobKind.DEPARTMENTS }
                        onItemSelect={ (v) => setJob(v) }
                    />
                    <EntityConditional
                        entity={ job }
                        render={ (v) => (
                        <JobDetails
                            job={ v }
                            onClose={ () => setJob(null) }
                            fetch={ jobFetch }
                            itemKey={ (v) => v.id }
                            fields={
                                {
                                    'Name':{ valueRender:(v) => v.name },
                                    'Result':{ valueRender:(v) => ToUpperFirst(v.kind) },
                                    'link':{
                                        hideHeader:true,
                                        valueRender:(v) =>
                                            <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/departments/${v.id}`) }/>
                                        },
                                }
                            }
                        />
                        ) }
                    />
                </Col>
            </Row>
        </BaseLayout>
    )
}

export default Departments