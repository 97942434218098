import { useState } from 'react'
import { Conditional,TError } from 'kdg-react'

import Icon, { ICON_KIND } from '../../../Components/Icon'
import Modal from '../../../Components/Modal'
import { AlertEmail, TAlertEmailForm } from '../../../Types/alertEmail'
import AlertEmailForm from './AlertEmailForm'
import { updateAlertEmail } from '../../../Api/alertEmails'
import { parseErrors } from '../Util/errors'

type Props = {
    alertEmail:AlertEmail
    onUpdate:() => void
}

const EditAlertEmail = (props:Props) => {
    const [showModal,setShowModal] = useState(false)
    const [saving,setSaving] = useState(false)
    const [form,setForm] = useState<TAlertEmailForm>(props.alertEmail)
    const [errors,setErrors] = useState<TError<TAlertEmailForm>>({})

    const close = () => {
        setShowModal(false)
        setSaving(false)
        setForm(props.alertEmail)
        setErrors({})
    }
    const save = async () => {
        setSaving(true)
        await updateAlertEmail({
            body:form,
            success:(_) => {
                close()
                props.onUpdate()
            },
            errorHandler:async (v) => {
                const t = await parseErrors(form,v)
                setErrors(t)
                setSaving(false)
            }
        })
    }
    return (
        <>
            <Icon kind={ ICON_KIND.Edit } onClick={ () => setShowModal(true) } />
            <Conditional
                condition={ showModal }
                onTrue={
                    () => {
                        return (
                            <Modal
                                size={ 'lg' }
                                onClose={ close }
                                header={ () => 'Edit Alert Email' }
                                content={
                                    () => (
                                        <AlertEmailForm
                                            alertEmail={ form }
                                            onChange={ (v) => setForm(v) }
                                            errors={ errors }
                                            save={ save }
                                            onCancel={ close }
                                            saving={ saving }
                                        />
                                    )
                                }
                            />
                        )
                    }
                }
            />
        </>
    )
}

export default EditAlertEmail