import { useState,useEffect } from "react"
import { ActionButton,Clickable } from "kdg-react"

import Modal from "./Modal"
import Icon,  { ICON_KIND,  } from "./Icon"
import { Filter, VendorLinkStatus, VendorStatus,NOT_ASSIGNED } from "../Types/vendor"
import Checkboxes from "./CheckBoxes"

type Props = {
    filter:Filter
    onChange:(_:Filter) => void
}

const ControlledModal = (props:Props) => {
    const [show, setShow] = useState(false)
    const [filter,setFilter] = useState(props.filter)

    const apply = () => {
        setShow(false)
        props.onChange(filter)
    }

    const close = () => {
        setShow(false)
        setFilter(props.filter)
    }

    useEffect(() => {
        setFilter(props.filter)
    },[props.filter.linked,props.filter.status])

    return (
    <>
        <div onClick={ () => setShow(true) } className='filter-button btn btn-light'>
            <Icon kind={ ICON_KIND.Filter } size={ 'xl' }/>
        </div>
        {
            show ?
            <Modal
                size="lg"
                onClose={ () => setShow(false) }
                header={() => 'Filters'}
                content={ () =>
                    <>
                        <Checkboxes
                            className="mt-3 mb-3 filters-contain"
                            label={ 'Vendor Status' }
                            onChange={ (v) => setFilter({ ...filter, status:v }) }
                            value={ filter.status }
                            options={ Object.values(VendorStatus).filter((v) => isNaN(Number(v))).map((v) => v as string).concat([NOT_ASSIGNED]) }
                            parseOptionLabel={ (v) => v }
                        />
                        <Checkboxes
                            className="mt-4 mb-2 filters-contain"
                            label={ 'Linked?' }
                            options={ Object.values(VendorLinkStatus).filter((v) => isNaN(Number(v))) }
                            value={ filter.linked }
                            onChange={ (v) => setFilter({ ...filter,linked:v }) }
                            parseOptionLabel={ (v) => `View ${v}` }
                        />
                    </>
                }
                footer={ () =>
                    <div className="d-flex align-items-center justify-content-end">
                        <Clickable onClick={ () => setFilter(props.filter) }>Reset Filters</Clickable>
                        <Clickable onClick={ close } className="grey-link btn btn-link">Cancel</Clickable>
                        <ActionButton onClick={ apply } className='btn-md'>Apply</ActionButton>
                    </div>
                }
            />
            : null
        }
    </>
    )
}

export default ControlledModal