import { ReactNode,Fragment } from 'react'
import { Table,Conditional } from 'kdg-react'

import NoData from './NoData'

type Props<T extends {}> = React.ComponentProps<typeof Table<T>> & {
  noDataMessage:ReactNode
  footer?:ReactNode
}

const TableWithNoData = <T extends {}>(props:Props<T>) => {
  return (
    <Conditional
      condition={ !!props.items.length }
      onFalse={
        () => (
          <NoData message={ props.noDataMessage } />
        )
      }
      onTrue={
        () => (
          <Fragment>
            <Table
              { ...props }
            />
            { props.footer }
          </Fragment>

        )
      }
    />
  )
}

export default TableWithNoData
