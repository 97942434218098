import { Instant } from '@js-joda/core'
export enum JobKinds {
  PULL_VENDORS="Pull Vendors",
  PULL_INTACCT_VENDORS="Pull Intacct Vendors",
  PULL_VENDOR_CONTACTS="Pull Vendor Contacts",
  SEND_VENDORS="Send Vendors To Intacct",
  PULL_BILLS="Bills Pull",
  SEND_BILLS="Send Bills",
  PULL_DEPARTMENTS="Departments Pull",
  SEND_DEPARTMENTS_INTACCT="Departments Send To Intacct",
  SEND_DEPARTMENTS_CRM="Send Departments To CRM",
  PULL_PROJECTS="Pull Zoho Projects",
  LOCATIONS_PULL="Pull Locations",
  LOCATIONS_SEND="Send Locations",
}

export const parseJobKinds = (x:string) => {
  switch (x) {
    case JobKinds.PULL_VENDORS:
      return JobKinds.PULL_VENDORS
    case JobKinds.PULL_INTACCT_VENDORS:
      return JobKinds.PULL_INTACCT_VENDORS
    case JobKinds.PULL_VENDOR_CONTACTS:
      return JobKinds.PULL_VENDOR_CONTACTS
    case JobKinds.PULL_PROJECTS:
      return JobKinds.PULL_PROJECTS
    case JobKinds.SEND_VENDORS:
      return JobKinds.SEND_VENDORS
    case JobKinds.PULL_BILLS:
      return JobKinds.PULL_BILLS
    case JobKinds.SEND_BILLS:
      return JobKinds.SEND_BILLS
    case JobKinds.PULL_DEPARTMENTS:
      return JobKinds.PULL_DEPARTMENTS
    case JobKinds.SEND_DEPARTMENTS_INTACCT:
      return JobKinds.SEND_DEPARTMENTS_INTACCT
    case JobKinds.SEND_DEPARTMENTS_CRM:
      return JobKinds.SEND_DEPARTMENTS_CRM
    case JobKinds.LOCATIONS_PULL:
        return JobKinds.LOCATIONS_PULL
    case JobKinds.LOCATIONS_SEND:
        return JobKinds.LOCATIONS_SEND
    default:
      throw `Unknown JobKind: ${x}`
  }
}

export const parseRecordJobKind = (x:string) => {
  switch (x) {
    case RecordActions.CREATE:
      return RecordActions.CREATE
    case RecordActions.UPDATE:
      return RecordActions.UPDATE
    case RecordActions.ERROR:
      return RecordActions.ERROR
    default:
      throw `Unknown RecordJobKind: ${x}`
  }
}

export type Job = {
  id:string
  name: JobKinds
  start: Instant
  finish: Instant
  error:string|null
  created:number
  updated:number
  errors:number
}

export enum RecordActions {
  CREATE='created',
  UPDATE='updated',
  ERROR='error'
}

export type RecordJob = {
  jobId:string
  error:string|null
  jobName:JobKinds
  kind:RecordActions
  occurred:Instant
  recordId:string
}

export enum Entities {
  TOP_LEVEL = 'Top level',
  SMSI = 'SMSI'
}
