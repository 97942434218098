import { useState } from 'react'

import { ActionButton, Conditional, TError } from "kdg-react"
import Modal from '../../../Components/Modal'
import AlertEmailForm from './AlertEmailForm'
import { TAlertEmailForm } from '../../../Types/alertEmail'
import { createAlertEmail } from '../../../Api/alertEmails'
import Icon, { ICON_KIND } from '../../../Components/Icon'
import { parseErrors } from '../Util/errors'

type Props = {
    onSave:() => void
}

export const CreateAlertEmail = (props:Props) => {
    const [showModal,setShowModal] = useState(false)
    const [alertEmail,setAlertEmail] = useState<TAlertEmailForm>({ id:null,emails:[{ email:'',onFailure:false,onSuccess:false,id:'' }],job:null,name:'' })
    const [errors,setErrors] = useState<TError<TAlertEmailForm>>({})
    const [saving,setSaving] = useState(false)
    const create =  async () => {
        setSaving(true)
        createAlertEmail({
            body:alertEmail,
            success:(_) => {
                close()
                setSaving(false)
                props.onSave()
            },
            errorHandler:async (errors) => {
                const t = await parseErrors(alertEmail,errors)
                setErrors(t)
                setSaving(false)
            }
        })
    }

    const close = () => {
        setShowModal(false)
        setSaving(false)
        setErrors({})
        setAlertEmail({ id:null,emails:[],job:null,name:'' })
    }

    return (
        <>
            <ActionButton onClick={ () => setShowModal(true) }> Add Email <Icon kind= {ICON_KIND.Add} /></ActionButton>
            <Conditional
                condition={ showModal }
                onTrue={ () => (
                <Modal
                    size={ 'lg' }
                    onClose={ () => setShowModal(false) }
                    header={ () => 'Create Alert Email' }
                    content={
                        () => (
                            <div>
                                <AlertEmailForm
                                    alertEmail={ alertEmail }
                                    onChange={ setAlertEmail }
                                    save={ create }
                                    errors={ errors }
                                    onCancel={ () => close() }
                                    saving={ saving }
                                />
                            </div>
                        )
                    }
                />
                )}
            />
        </>
    )
}