export enum PermissionGroup {
  SYSTEM_ADMIN = "system-admin",
  USER = "user",
}

export enum Permission {
  CREATE_USERS='create-users',
  EDIT_USERS='edit-users',
  VIEW_VENDORS='view-vendors',
  VIEW_EMPLOYEES='view-employees',
  VIEW_BILL_SYNC='view-bill-sync',
  VIEW_BILLS='view-bills',
  VIEW_DEPARTMENTS='departments-view-all',
  VIEW_PROJECTS='view-projects',
  VIEW_LOCATIONS='locations-view-all',
}