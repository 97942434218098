import { createRoot } from 'react-dom/client';
import AppRouter from './Routing/Routing';
import { AuthContextProvider, defaultAuthContext } from './Context/AuthContext';
import { ErrorContextProvider } from './Context/ErrorContext';
import './assets/css/style.scss'
import  { NotificationProvider } from 'kdg-react';


// Clear the existing HTML content
document.body.innerHTML = '<div id="app"></div>';

var app = document.getElementById('app')

if(app !== null)
{
  // Render your React component instead
  const root = createRoot(app);
  root.render(
    <NotificationProvider notificationClassName='my-2 shadow-lg'>
      <AuthContextProvider {...defaultAuthContext}>
        <ErrorContextProvider>
          <AppRouter/>
        </ErrorContextProvider>
      </AuthContextProvider>
    </NotificationProvider>

  );
}
else
{
  throw "Cannot find root"
}
