import { useState } from "react"
import { SortOrder } from "kdg-react"

export type TTableSort<K extends string,T> = {
    field:K
    sortOrder:SortOrder
    sort:(a:T,b:T)=>number
}

export type TSortFields = {
    fieldName:string
    order:SortOrder
}

type TMappedSortField<K extends string> = {
  [Property in K]:string
}

type TTableSortArgs<K extends string, T extends {}> = {
  /**
   * @description used to map field values to arbitrary strings, useful when sending sort fields to an external API where values might differ
   * */
  mapping:TMappedSortField<K>
  initialState:TTableSort<K,T>
}

export const defaultSort = <T>(_a:T,_b:T) => 1


export const useTableSort = <K extends string, T extends {}>(
  args:TTableSortArgs<K,T>
) => {
  const [tableSort,setTableSort] = useState<TTableSort<K,T>>(args.initialState)

  // TODO: if this hook ever makes it to the kdg react library, this function will have to be factored out somehow
  const mappedSortFields:TSortFields =  {
      fieldName:args.mapping[tableSort.field],
      order:tableSort.sortOrder,
    }

  return {
    tableSort,
    mappedSortFields,
    setTableSort,
  }
}