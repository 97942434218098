import { useState,useEffect } from 'react'
import { Col,Row,Card,DelayedTextInput,DiscriminatedUnionHandler,Loader,Pagination } from 'kdg-react'

import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import BaseLayout from '../../Components/BaseLayout'
import { EmployeeMeta } from '../../Types/employee'
import { getEmployees } from '../../Api/employees'
import Table from '../../Components/TableWithNoData'
import { formatDate } from '../../Util/datetime'
import { usePagination,useAppNavigation } from '../../Hooks/hooks'
import Icon, { ICON_KIND } from '../../Components/Icon'
import { paginationOptions } from '../../Types/pagination'

const Employees = () => {
  const [search,setSearch] = useState("")
  const [total,setTotal] = useState(0)
  const [employees, setEmployees] = useState<TLoadedData<EmployeeMeta[]>>(defaultLoadedData)
  const paginator = usePagination({ numberOfItemsPerPage:10,page:1 })
  const navigate = useAppNavigation()
  const fetch = async () => {
    setEmployees(defaultLoadedData)
    await getEmployees(search,paginator.pagination,{
      success:(v) => {
        setEmployees({ case:TLoadedCase.LOADED,value:v.results })
        setTotal(v.count)
      },
      errorHandler:(error) => {
        setEmployees({ case:TLoadedCase.ERROR, value:'cannot load Employees' })
        console.error('error',error)
      }
    })
  }
  useEffect(() => { fetch() },[search,paginator.pagination])

  return (
    <BaseLayout
      pageTitle={ 'Employees' }
    >
      <Row fluid>
        <Col md={ 8 } lg={ 9 }>
          <Card
            header={
              {
                className:'d-flex align-items-center',
                content:
                  <div className="d-flex align-items-center me-3">
                    <DelayedTextInput
                      placeholder="Search..."
                      value={ search }
                      delay={ 300 }
                      onDelay={
                        (v) => {
                          setSearch(v || "")
                        }
                      }
                     />
                  </div>
              } 
            }
            body={
              {
                content:(
                  <DiscriminatedUnionHandler
                    value={ employees }
                    config={
                      {
                        [TLoadedCase.LOADING]: () => <Loader />,
                        [TLoadedCase.ERROR]: e => e,
                        [TLoadedCase.LOADED]:({ value:v }) => (
                          <Table
                            noDataMessage={ 'There are no employees to display.' }
                            footer={
                              <Pagination pagination={ paginator.pagination } totalRecords={ total } pageOptions={ paginationOptions } onChange={ paginator.update } optionsDirection='top'/>
                            }
                            headerClassName='table-light'
                            className={ 'table-hover row-border mb-0' }
                            items={ v }
                            itemKey={ (v) => v.id }
                            fields={
                              {
                                'Name':{ valueRender:(v) => v.name },
                                'Email': { valueRender:(v) => v.workEmail },
                                'Supervisor Email': { valueRender:(v) => v.supervisorEmail },
                                'Hire Date':{ valueRender:(v) => v.hireDate ? formatDate(v.hireDate) : null },
                                'Action':{ hideHeader:true, valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight} onClick={ () => navigate(`/employee/${v.id}`)} /> }
                              }
                            }
                          />
                        )
                      }
                    }
                  />
                )
              }
            }
          />
        </Col>
      </Row>
    </BaseLayout>
  )
}
export default Employees
