import { success } from '../Previews/error-smsi-email-notification'

type Props = {
    onClick?:() => void
}

const Temp = (props:Props) => {
    const t = success
        .replace('##Name##','[Module Name]')
        .replace('##Count##','[Record Count]')
        .replace('##Direction##','[App] to [App]')
        .replace('##Names##',[1,2,3].map((v) => `<li style=\"margin-bottom: 0; text-align: left;\">Record ${v}</li>`).join(`\n`))
        .replaceAll('##Url##',window.location.hostname)
    return (
        <div onClick={ props.onClick } dangerouslySetInnerHTML={ { __html:t } }></div>
    )
}

export default Temp
