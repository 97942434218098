import React from 'react'
import { Loader, DelayedTextInput, ActionButton, EntityConditional,DiscriminatedUnionHandler, Enums, useNotification, SortOrder } from 'kdg-react'

import BaseLayout from '../../Components/BaseLayout'
import { getUsers } from '../../Api/users'
import { initialUserForm, User, TUserForm, userToUserForm,UserType,UserFilter } from '../../Types/user'
import UserForm from './Components/UserForm'
import Icon, { ICON_KIND } from '../../Components/Icon'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import Table from '../../Components/TableWithNoData'
import Checkboxes from '../../Components/CheckBoxes'
import Filter from '../../Components/Filter'
import { PermissionGroup } from '../../Types/permission'
import { defaultSort, useTableSort } from '../../Hooks/sort'

enum ESortableTableFields {
    Name='Name',
    Email='Email',
    Alias='Alias',
    Role='Role',
}

const UserManagement = () => {
  const [userForm, setUserForm] = React.useState<TUserForm |null>(null)
  const [search,setSearch] = React.useState('')
  const [filter,setFilter] = React.useState<UserFilter>({ roles:[PermissionGroup.SYSTEM_ADMIN,PermissionGroup.USER] })

  const [userType,setUserType] = React.useState<UserType[]>([UserType.ACTIVE])

  const [users, setUsers] = React.useState<TLoadedData<User[]>>(defaultLoadedData)
  const { notify } = useNotification()

  const nameSort = (a:User,b:User) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())

    const { tableSort, setTableSort } = useTableSort<ESortableTableFields,User>({
        mapping:{
            [ESortableTableFields.Name]:'first_name',
            [ESortableTableFields.Email]:'email',
            [ESortableTableFields.Alias]:'alias',
            [ESortableTableFields.Role]:'role',
        },
        initialState: {
            field:ESortableTableFields.Name,
            sort:defaultSort,
            sortOrder:SortOrder.ASC,
        }
    })

    const parseTableField = (x:string):ESortableTableFields => {
        switch (x) {
            case ESortableTableFields.Name:
            case ESortableTableFields.Email:
            case ESortableTableFields.Alias:
            case ESortableTableFields.Role:
            return x
          default:
            throw new Error(`unable to parse table field: ${x}`)
        }
      }


  const fetch = async () => {
    setUsers(defaultLoadedData)
    await getUsers(search,userType,filter,{
      success:(users) => setUsers({ case: TLoadedCase.LOADED, value:users }),
      errorHandler:(_) => setUsers({ case: TLoadedCase.ERROR, value:'unable to load Jobs' }),
    })
  }
  React.useEffect(() => {
    fetch()
  },[search,userType,filter.roles])


  return (
    <BaseLayout pageTitle={ 'User Management' }>
      <EntityConditional
        entity={userForm}
        render={form =>
          <UserForm
            onSave={() => {
              setUserForm(null)
              fetch()
              notify({
                key:new Date().getUTCMilliseconds(),
                message:'User Saved Successfully',
                type:Enums.Color.Success,
                autoDismiss:3000
              })
            }}
            onClose={() => setUserForm(null)}
            user={form}
          />
        }
      />

      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
            <div className='d-flex align-items-center'>
                <div className="d-flex align-items-center">
                    <DelayedTextInput
                        className="me-3"
                        placeholder="Search Name or Email..."
                        value={ search }
                        delay={ 300 }
                        onDelay={
                            (v) => {
                                setSearch(v || "")
                            }
                        }
                    />
                </div>
                <Checkboxes
                    className={ 'inline-filter' }
                    options={ Object.values(UserType).filter((v) => isNaN(Number(v))) }
                    value={ userType }
                    onChange={ setUserType }
                    label={ 'User Type' }
                    parseOptionLabel={ (v) => v }
                />
                <Filter
                    filter={ filter }
                    onChange={ setFilter }
                    form={ (filter,set) => (
                        <Checkboxes
                            label={ 'Roles' }
                            value={ filter.roles }
                            onChange={ (v) => set({ ...filter, roles:v }) }
                            options={ Object.values(PermissionGroup).filter((v) => isNaN(Number(v))) }
                            parseOptionLabel={ (v) => v }
                        />
                    ) }
                />
            </div>
            <div className={ 'd-flex justify-content-end align-items-center' }>
                <ActionButton
                    onClick={() => setUserForm(initialUserForm)}
                >Add User</ActionButton>
            </div>
        </div>
        <div className="card-body">
          <DiscriminatedUnionHandler
            value={ users }
            config={ {
              [TLoadedCase.LOADING]: () => <Loader />,
              [TLoadedCase.ERROR]: e => e,
              [TLoadedCase.LOADED]:({ value:v }) => (
                  <Table
                    noDataMessage={ 'There are no users to display.' }
                    className="table-hover"
                    headerClassName="table-light"
                    onSort={
                        (v) => setTableSort({
                            field:parseTableField(v.field.toString()),
                            sort:v.sort.sort,
                            sortOrder:v.sort.order,
                        })
                    }
                    defaultSort={
                        {
                            field:tableSort.field,
                            sort:{
                                sort:tableSort.sort,
                                order:tableSort.sortOrder,
                            }
                        }
                    }
                    items={ v }
                    itemKey={ (v) => v.id }
                    fields={
                      {
                        [ESortableTableFields.Name]: { valueRender:(v) => `${v.firstName} ${v.lastName}`,sort:{ sort:nameSort,order:SortOrder.ASC } },
                        [ESortableTableFields.Email]: { valueRender:(v) => v.email,sort:{ sort:(a,b) => a.email.localeCompare(b.email),order:SortOrder.ASC } },
                        [ESortableTableFields.Alias]: { valueRender:(v) => v.alias,sort:{ sort:(a,b) => {
                            if(!a.alias)
                                return 1
                            if(!b.alias)
                                return -1
                            return a.alias.localeCompare(b.alias)
                         } ,order:SortOrder.ASC } },
                        'Role': { valueRender:(v) => v.permissionGroup,sort:{ sort:(a,b) => a.permissionGroup.localeCompare(b.permissionGroup),order:SortOrder.ASC } },
                        'Actions': {
                          hideHeader: true,
                          valueRender: (v) => (
                            <Icon
                              kind={ICON_KIND.Edit}
                              onClick={() => setUserForm(userToUserForm(v))}
                            />
                          )
                        }
                      }
                    }
                  />
                )
              }
            }
          />
        </div>
      </div>
    </BaseLayout>
  )
}

export default UserManagement
