import { Fragment, useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import { DiscriminatedUnionHandler,Loader,Row,Col,Card,ActionButton, EntityConditional, Clickable, Conditional } from 'kdg-react'

import BaseLayout from '../../Components/BaseLayout'
import Icon, { ICON_KIND } from '../../Components/Icon'
import { fetchProject } from '../../Api/projects'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { useAppNavigation } from '../../Hooks/hooks'
import { Project } from '../../Types/projects'
import Field from '../Vendors/Components/Field'
import { formatDate } from '../../Util/datetime'


const PurchaseOrderView = () => {
  const params = useParams<{ id:string }>()
  const [project, setProject] = useState<TLoadedData<Project>>(defaultLoadedData)
  const navigate = useAppNavigation()

  const fetch = async () => {
    await fetchProject(params.id || '',{
      success:(v) => {
        setProject({
            case: TLoadedCase.LOADED,
            value: v,
        })
      },
      errorHandler:() => {
        setProject({
            case: TLoadedCase.ERROR,
            value: 'Unable to load Project',
        })
      }
    })
   }

  useEffect(() => {
    fetch()
  },[params.id])

  return (
    <BaseLayout
      pageTitle={ 'Project ' }
    >
    <DiscriminatedUnionHandler
      value={project}
      config={{
        [TLoadedCase.LOADING]: () => <Loader />,
        [TLoadedCase.ERROR]: e => e,
        [TLoadedCase.LOADED]: ({ value:v }) => {
          return (
            <Row fluid className={ 'mb-5' }>
              <Col md={8} lg={9}>
              <EntityConditional
                    entity={ v.syncError }
                    render={
                      (v) => (
                        <div className="alert alert-danger show d-flex justify-content-between align-items-center" role="alert">
                          <h3>
                            <Icon className='me-2' kind={ ICON_KIND.Ban } />
                            Incoming Sync Error: { v }
                          </h3>
                        </div>
                      )
                    }
                  />
                <Card
                  body={ {
                    content:
                      <div className="d-flex justify-content-between">
                        <div className='d-flex'>
                          <ActionButton
                            className='btn-light me-3'
                            onClick={ () => history.back() }
                          >
                            <Icon kind={ICON_KIND.ArrowLeft}/>
                          </ActionButton>
                          <div>
                            <div className="d-flex align-items-center">
                              <h1>{ v.projectName }</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    } }
                />
                <EntityConditional
                  entity={ v }
                  render={
                    (project) => (
                      <Fragment>
                        <h6 className={ 'mt-4' }>Project</h6>
                        <Card
                          body={ {
                            content:
                            <Fragment>
                                <Row>
                                    <Col md={6}>
                                        <Field label={ 'Name' } value={ project.projectName } />
                                        <Field label={ 'Project Type' } value={ project.projectType } />
                                        <Field label={ 'Zoho ID' } value={ project.zohoId } />
                                        <Field label={ 'Start Date' } value={ project.startDate ? formatDate(project.startDate) : null } />
                                        <Field label={ 'Customer' } value={ project.customer } />
                                        <Field label={ 'Status' } value={ project.status } />
                                        <Conditional
                                          condition={!!project.parentProject.projectName}
                                          onTrue={() =>                                                                                    
                                            <Field label={ 'Parent Project'} value={
                                              <Clickable className={'clickable'} onClick={ () => navigate(`/projects/${project.parentProject.id}`) }>
                                                { project.parentProject.projectName }
                                              </Clickable>} 
                                            />}
                                          onFalse={() => null}

                  
                                        />

                                    </Col>
                                    <Col md={6}>
                                        <Field label={ 'Project Number' } value={ project.projectNumber } />
                                        <Field label={ 'Project Category' } value={ project.projectCategory } />
                                        <Field label={ 'SMSI Quote #' } value={ project.SMSIQuoteNumber } />
                                        <Field label={ 'End Date' } value={ project.endDate ? formatDate(project.endDate) : null } />
                                        <Field label={ 'Customer PO' } value={ project.customerPO } />
                                        <Field label={'Department'} value={ project.department?.name } />

                                    </Col>
                                </Row>
                            </Fragment>
                          } }
                        />
                        <h6 className={ 'mt-4' }>Notes</h6>
                        <Card
                            body={ { 
                                content:
                                <Fragment>
                                    <Field label={ 'Details' } value={ project.details } />
                                </Fragment>
                            } }
                        />
                      </Fragment>
                    )
                  }
                />         
              </Col>
            </Row>
          )
        }
      }}
    />
    </BaseLayout>
  )
}

export default PurchaseOrderView
