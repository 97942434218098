import { useState,ReactNode } from 'react'
import { Clickable,ActionButton } from 'kdg-react'

import Icon, { ICON_KIND } from './Icon'
import Modal from './Modal'

type Props<T> = {
    filter:T
    onChange:(_:T) => void
    form:(_filter:T,_set:React.Dispatch<React.SetStateAction<T>>) => ReactNode
}

const Filter = <T,>(props:Props<T>) => {
    const [show, setShow] = useState(false)
    const [filter,setFilter] = useState(props.filter)

    const apply = () => {
        setShow(false)
        props.onChange(filter)
    }

    const close = () => {
        setShow(false)
        setFilter(props.filter)
    }

    return (
    <>
        <div onClick={ () => setShow(true) } className='filter-button btn btn-light'>
            <Icon kind={ ICON_KIND.Filter } size={ 'xl' }/>
        </div>
        {
            show ?
            <Modal
                size="lg"
                onClose={ () => setShow(false) }
                header={() => 'Filters'}
                content={ () => props.form(filter,setFilter)}
                footer={ () =>
                    <div className="d-flex align-items-center justify-content-end">
                        <Clickable onClick={ () => setFilter(props.filter) }>Reset Filters</Clickable>
                        <Clickable onClick={ close } className="grey-link btn btn-link">Cancel</Clickable>
                        <ActionButton onClick={ apply } className='btn-md'>Apply</ActionButton>
                    </div>
                }
            />
            : null
        }
    </>
    )
}

export default Filter