import { EntityConditional } from 'kdg-react'
import { Fragment, createContext,useContext,useState } from 'react'
import { Option } from '../Types/util'
import Modal from '../Components/Modal'

type ErrorContext = {
  setError: (_: Option<string>) => void
  error: Option<string>
}

const ErrorContext = createContext<Option<ErrorContext>>(null)

export const ErrorContextProvider: React.FC = ({ children }) => {
  const [error, _setError] = useState<Option<string>>(null)

  const setError = (err:Option<string>) => {
    console.error(err)
    _setError(err)
  }

  return (
    <ErrorContext.Provider
      value={ { setError,error } }
    >
      {children}
      <EntityConditional
        entity={ error }
        render={
          (v) => (
            <Modal
              onClose={ () => setError(null) }
              header={ () => <div>Error</div>}
              content={ () => (
                  <Fragment>
                    <div className="d-flex flex-column align-items-center justify-content-center pb-3">
                      <img className="mb-4" src={ '/src/assets/img/error-modal.png' } width="200px" />
                      <h4 className='mb-2 text-muted'>Uh oh, something unexpected happened:</h4>
                      <h2>{ v }</h2>
                    </div>
                  </Fragment>
                )
              }
            />
          )
        }
      />
    </ErrorContext.Provider>
  )
}

export const useErrorContext = () => {
  const errorContext = useContext(ErrorContext);
  if (!errorContext) {
    throw new Error("useErrorContext must be used within a ErrorContextProvider");
  }
  return errorContext;
}
