import React from 'react'

type Props<A> = {
  items: A[]
  extractKey: (_: A) => React.Key
  renderItem: (_:A) => React.ReactNode
  className?: string
  itemClass?: string
  onItemClick?: (_:A) => void
}

const List = <A,>(props:Props<A>) => {
  return (
    <ul className={ props.className }>
      {
        props.items.map((v) => (
          <li key={ props.extractKey(v) } className={ props.itemClass } onClick={ () => props.onItemClick ? props.onItemClick(v) : undefined }>
            { props.renderItem(v) }
          </li>
        ))
      }
      
    </ul>
  )
}

export default List
