import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import { DiscriminatedUnionHandler, Loader, Row, Col, Card, ActionButton, EntityConditional } from 'kdg-react'

import BaseLayout from "../../Components/BaseLayout"
import { fetchDepartment } from '../../Api/departments'
import { Department } from '../../Types/department';
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import Icon, { ICON_KIND } from '../../Components/Icon'
import Field from '../Vendors/Components/Field'
import Section from '../../Components/Section'
import StatusBadge from './Components/StatusBadge'

const DepartmentView = () => {
    const params = useParams<{ id:string }>();
    const [department, setDepartment] = useState<TLoadedData<Department>>(defaultLoadedData);

    const fetch = async () => {
        await fetchDepartment(params.id || '',{
            success:(b) => {
                setDepartment({
                  case: TLoadedCase.LOADED,
                  value: b,
              });
            },
            errorHandler:() => {
                setDepartment({
                  case: TLoadedCase.ERROR,
                  value: 'unable to load Bill',
              });
            }
          });
    }

    useEffect(() => { fetch() },[])
    return (
        <BaseLayout pageTitle={ 'Department' }>
            <DiscriminatedUnionHandler
                value={ department }
                config={{
                    [TLoadedCase.LOADING]: () => <Loader />,
                    [TLoadedCase.ERROR]: e => e,
                    [TLoadedCase.LOADED]: ({ value:b }) => {
                    return (
                        <Row fluid className={ 'mb-5' }>
                            <Col md={8} lg={9}>
                                <EntityConditional
                                    entity={ b.error }
                                    render={
                                        (b) => (
                                            <div className="alert alert-danger show d-flex justify-content-between align-items-center" role="alert">
                                                <h3>
                                                <Icon className='me-2' kind={ ICON_KIND.Ban } />
                                                Incoming Sync Error: { b }
                                                </h3>
                                                {/*<button className='btn btn-danger'>Review and Resolve</button>*/}
                                            </div>
                                        )
                                    }
                                />
                            <Card
                                body={ {
                                    content:
                                    <div className="d-flex justify-content-between">
                                        <div className='d-flex'>
                                            <ActionButton
                                                className='btn-light me-3'
                                                onClick={ () => history.back() }
                                            >
                                                <Icon kind={ICON_KIND.ArrowLeft}/>
                                            </ActionButton>
                                            <div>
                                                <div className="d-flex align-items-center">
                                                    <h1>{ b.name }</h1>
                                                    <EntityConditional
                                                        entity={b.status}
                                                        render={status => (
                                                        <StatusBadge
                                                            status={status}
                                                            className={ 'rounded-pill text-white ms-2 text-bg-success' }
                                                        />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    } }
                                />
                                <Section title={ 'General' }>
                                    <Row>
                                        <Col md={6}>
                                            <Field label={ 'Intacct ID' } value={ b.intacctId }/>
                                            <Field label={ 'Creator ID' } value={ b.zohoId }/>
                                        </Col>
                                        <Col md={6}>
                                            <Field label={ 'CRM ID' } value={ b.crmId } />
                                            <Field label={ 'Parent' } value={ b.parentDepartment ? b.parentDepartment.name : null }/>
                                        </Col>
                                    </Row>
                                </Section>
                                <Section title={ 'Emails' }>
                                    <Row>
                                        <Col md={6}>
                                            <Field label={ 'Accounting' } value={ b.accountingEmail }/>
                                            <Field label={ 'Timesheet' } value={ b.timesheetEmail }/>
                                        </Col>
                                        <Col md={6}>
                                            <Field label={ 'Sales' } value={ b.salesEmail } />
                                        </Col>
                                    </Row>
                                </Section>
                            </Col>
                        </Row>
                    )
                    }
                }}
            />
        </BaseLayout>
    )
}

export default DepartmentView