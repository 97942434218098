import BaseLayout from '../../Components/BaseLayout'

const ScheduledTasks = () => {

  return (
    <BaseLayout
      pageTitle={ 'Hangfire' }
    >
        <div className="card">
            <div className="card-body">
                <iframe src="/hangfire" style={{width:'100%', height:'calc(100vh - 150px)', marginBottom:'-8px'}}></iframe>
            </div>
        </div>
    </BaseLayout>
  )
}

export default ScheduledTasks