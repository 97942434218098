import { Address } from "./address"
import { Instant } from '@js-joda/core'
import { composedBooleanValidatedString } from 'kdg-react'
import { Option } from './util'
import { SyncStatus } from './sync'

export enum SortableFields {
    VendorName="Vendor Name",
    SMSIAccountNumber="SMSI Account Number",
    VendorStatus="Vendor Status",
    LastUpdated="Last Updated",
    Errors="Errors",
}

export enum VendorStatus {
  ACTIVE='Active',
  INACTIVE='Inactive',
  DO_NOT_USE='Do Not Use',
  SEE_ACCOUNTING='See Accounting',
}
export const NOT_ASSIGNED = 'Not Assigned'

export enum VendorLinkStatus {
    LINKED='Linked',
    UNLINKED='Unlinked',
}

export type VendorMeta = {
  id:string
  name:string | null
  account_number: string | null
  status:VendorStatus | null
  lastUpdated:Instant
  syncStatus:SyncStatus
  error:Option<string>
}

export type VendorInfo = {
  id:string
  name:string
  intacctId:Option<string>
  zohoId:string
}

export type VendorContact = {
  name:string | null
  category:string | null
}

type PaymentTerm = {
  zohoId:null
  name:string
}

export type Vendor = {
  id:string
  name: string | null
  print_as: string | null
  status: VendorStatus | null
  account_number: string | null
  zohoId: string | null
  intacctId: string | null
  website: string | null
  address: {
    [Property in keyof Address]: Address[Property] | null
  }
  phone: string | null
  fax: string | null
  payment_terms: PaymentTerm | null
  billing_type: string | null
  contact: string | null
  vendor_contacts:VendorContact[]
  syncError:string|null
  taxId:string|null
}

export type ContactMeta = {
  id: string
  email: string
  name: string
  phone: string
}

export type Filter = {
    status:string[]
    linked:VendorLinkStatus[]
}

export const defaultFilter = { status:[VendorStatus.ACTIVE,VendorStatus.DO_NOT_USE,VendorStatus.INACTIVE,VendorStatus.SEE_ACCOUNTING,NOT_ASSIGNED], linked:[VendorLinkStatus.LINKED,VendorLinkStatus.UNLINKED] }

export const vendorAddressDisplay = (x: Vendor['address']) =>
  composedBooleanValidatedString([
    [x.address_1 || '',!!x.address_1],
    [x.address_2 || '', !!x.address_2],
    [x.city || '', !!x.city],
    [x.state || '', !!x.state],
    [x.zip || '', !!x.zip],
  ])

export namespace Responses {
  export type VendorInfo = {
    id:string
    intacctId:Option<string>
    name:string
    zohoId:string
  }
}