import { Instant,DateTimeFormatter,ZonedDateTime,LocalDate,Duration } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import '@js-joda/timezone'
import { Option } from '../Types/util'
import { DateUtils } from 'kdg-react'

const DATE_FORMAT = 'M/d/uu';

const now = () => ZonedDateTime.now()
export const parseInstant = (x:string) => Instant.parse(x)
export const tryParseDate = (x:string) : Option<LocalDate> => {
  try {
    return DateUtils.LocalDate.parse(x)
  }
  catch(_)
  {
    return null
  }
}

const getFormatter = (v:string) => DateTimeFormatter.ofPattern(v).withLocale(Locale.US)

export const formatInstant = (v:Instant) => {
  const datetime = v.atZone(now().zone()).toLocalDateTime()
  return `${datetime.format((getFormatter(`${DATE_FORMAT} '@' h:mm`)))}${datetime.format((getFormatter("a"))).toLowerCase()}`
}

export const formatDate = (v:LocalDate) => {
  return v.format(getFormatter(DATE_FORMAT));
}

export const instantToDate = (v:Instant) => {
   return `${v.atZone(now().zone()).toLocalDateTime().format((getFormatter(DATE_FORMAT)))}`
}

export const getHoursSince = (v:string) => {
  return `${Math.floor(Duration.between(parseInstant(v), now()).toHours())} hours ago`
}
