import { JobKinds, Entities } from './job'
import { Option } from './util'

export enum SyncStatus {
    ERROR='error',
    CREATED='created',
    UPDATED='updated',
  }


export enum AppDestinations {
  APP='App',
  CREATOR='Creator',
  INTACCT='Intacct',
  CRM='CRM',
  INTACCT_AND_CRM='CRM & Intacct',
}

export type SyncDirection = {
  from:AppDestinations
  to:AppDestinations
  entity: Option<Entities>
}

export const IntacctToCreator = {
    from:AppDestinations.INTACCT,
    to:AppDestinations.CREATOR,
    entity:null,
}

export const CreatorToIntacct = {
  from:AppDestinations.CREATOR,
  to:AppDestinations.INTACCT,
  entity: Entities.SMSI
}

export const CreatorToIntacctAndCRM = {
  from:AppDestinations.CREATOR,
  to:AppDestinations.INTACCT_AND_CRM,
  entity: Entities.SMSI
}

export const getDirections = (job:JobKinds) => {
  switch(job){
    case JobKinds.PULL_VENDORS:
    case JobKinds.PULL_VENDOR_CONTACTS:
    case JobKinds.PULL_BILLS:
      return { from: AppDestinations.CREATOR, to:AppDestinations.APP, entity: null }
    case JobKinds.PULL_PROJECTS:
      return { from: AppDestinations.CREATOR, to:AppDestinations.APP }
    case JobKinds.SEND_VENDORS:
      return { from:AppDestinations.APP, to:AppDestinations.INTACCT, entity: Entities.TOP_LEVEL }
    case JobKinds.SEND_BILLS:
    case JobKinds.SEND_DEPARTMENTS_INTACCT:
      return { from:AppDestinations.APP, to:AppDestinations.INTACCT, entity: Entities.SMSI }
    case JobKinds.PULL_INTACCT_VENDORS:
    case JobKinds.LOCATIONS_PULL:
      return { from:AppDestinations.INTACCT, to:AppDestinations.APP, entity: null }
    case JobKinds.PULL_DEPARTMENTS:
        return { from:AppDestinations.CREATOR, to:AppDestinations.APP, entity: null }
    case JobKinds.SEND_DEPARTMENTS_CRM:
        return { from:AppDestinations.APP, to:AppDestinations.CRM, entity: null }
    case JobKinds.LOCATIONS_SEND:
        return { from:AppDestinations.APP, to:AppDestinations.CREATOR, entity:null }

  }
}