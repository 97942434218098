import { Fragment,useState,useEffect,useCallback } from 'react'
import { Card,DiscriminatedUnionHandler,Loader,Conditional,TPagination,AsyncButton,Col,EntityConditional,RequestMethodArgs,SortOrder } from 'kdg-react'
import Icon, { ICON_KIND } from '../../../Components/Icon'

import { FetchJobKind, getJobs } from '../../../Api/jobs'
import { defaultLoadedData,TLoadedCase, TLoadedData } from '../../../Types/util'
import { Job, JobKinds } from '../../../Types/job'
import { getDirections } from '../../../Types/sync'
import { useAppNavigation } from '../../../Hooks/hooks'
import List from '../../../Components/List'
import SyncStats from '../../../Components/SyncStats'

import { formatInstant, instantToDate } from '../../../Util/datetime'
import JobDetails from '../../../Components/JobDetails'
import { getVendors } from '../../../Api/vendors'
import { PaginatedResults } from '../../../Types/pagination'
import { VendorMeta,defaultFilter } from '../../../Types/vendor'
import { ToUpperFirst } from '../../../Util/string'


type State = {
  jobs: Job[]
  success: number
  failed:number
}

const Counts = (props:{ amount:number, label:string}) => {
  return (
    <Conditional
      condition={ props.amount > 0 }
      onTrue={ () => <p className="small text-muted mb-0">{ props.amount } { props.label }</p> }
    />
  )
}

const Sync = () => {
  const [ jobs, setJobs ] = useState<TLoadedData<State>>(defaultLoadedData)
  const [paginator,setPaginator] = useState<TPagination>({ numberOfItemsPerPage:8,page:1 })
  const [loading,setLoading] = useState(false)
  const [previous,setPrevious] = useState<Job[]>([])
  const [total,setTotal] = useState(0)
  const [selected,setSelected] = useState<Job|null>(null)

  const navigate = useAppNavigation()

  const jobFetch = async (job:string,paginator:TPagination,args: RequestMethodArgs<PaginatedResults<VendorMeta>>) => {
    await getVendors('',job,['Successful','Errors'],defaultFilter,paginator,{ fieldName:'name',order:SortOrder.ASC },args)
  }

  const _load = useCallback(async () => {
    setLoading(true)
    await getJobs([JobKinds.PULL_VENDORS,JobKinds.PULL_INTACCT_VENDORS,JobKinds.PULL_VENDOR_CONTACTS,JobKinds.SEND_VENDORS],FetchJobKind.VENDORS,paginator,{
        success: (v) => {
          const all = previous.concat(v.paginationData.results)
          setPrevious(all)
          setTotal(v.paginationData.count)
          setJobs({
            case: TLoadedCase.LOADED,
            value: {
              jobs: all,
              success: v.results.success,
              failed:v.results.failed,
            },
          })
          setLoading(false)
        }
      })
  },[paginator])
  useEffect(() => {
    _load()
  },[_load])

  const handleLoadVendor = useCallback(async () => {
    await getJobs([JobKinds.PULL_VENDORS,JobKinds.PULL_INTACCT_VENDORS,JobKinds.PULL_VENDOR_CONTACTS,JobKinds.SEND_VENDORS],FetchJobKind.VENDORS,paginator,{
      success: (v) => {
        const all = previous.concat(v.paginationData.results)
        setPrevious(all)
        setTotal(v.paginationData.count)
        setJobs({
          case: TLoadedCase.LOADED,
          value: {
            jobs: all,
            success: v.results.success,
            failed:v.results.failed,
          },
        })
      }
    })
    setJobs(defaultLoadedData)
  }, [])

  useEffect(() => {
    handleLoadVendor()
  }, [handleLoadVendor])

  return (
    <Fragment>
      <DiscriminatedUnionHandler
        value={jobs}
        config={{
          [ TLoadedCase.LOADING ]: () => <Loader />,
          [ TLoadedCase.ERROR ]: e => e,
          [ TLoadedCase.LOADED ]: ({ value:state }) => (
            <Fragment>
              <div className='row ps-2 pe-2'>
                <Col className='m-0 p-0 ps-1 pe-1' xl={ 6 } >
                  <SyncStats
                    amount={state.success}
                    kind={ 'success' }
                  />
                </Col>
                <Col className='m-0 p-0 ps-1 pe-1 ' xl={ 6 } >
                  <SyncStats
                    amount={state.failed}
                    kind={'error'}
                  />
                </Col>
              </div>
              <Card
                className='mb-3'
                body={{
                  className: '',
                  content:
                    <div>
                      <List
                        items={state.jobs}
                        className={'history-log'}
                        itemClass={'clickable'}
                        extractKey={(v) => v.id}
                        onItemClick={
                          (v) => {
                            setSelected(v)
                          }
                        }
                        renderItem={(v) => {
                          const direction = getDirections(v.name)
                          return (
                            <Fragment>
                              <div
                                className={ `d-flex justify-content-between align-items-center mb-1 ${ v.errors ? 'fail-sync' : 'success-sync' }` }
                              >
                                <p className="pe-3">{ direction.from } <Icon kind={ ICON_KIND.ArrowRight } /> { direction.to }{direction.entity && <span className='text-muted'>: {direction.entity}</span> } </p>
                                <p className="timestamp text-muted small">{ formatInstant(v.start) }</p>
                              </div>
                              <div className="ps-3 border-start">
                                <Counts amount={ v.created } label={ 'additions' } />
                                <Counts amount={ v.updated } label={ 'updates' } />
                                <Counts amount={ v.errors } label={ 'errors' } />
                                <Conditional
                                  condition={ !v.created && !v.updated && !v.errors }
                                  onTrue={
                                    () => <p className="small text-muted mb-0">No changes</p>
                                  }
                                />
                              </div>
                            </Fragment>
                          )
                        } }
                      />
                      <Conditional
                        condition={ total > state.jobs.length }
                        onTrue={
                          () => (
                            <div className='d-flex justify-content-center py-2'>
                              <AsyncButton loading={ loading }  disabled={ loading } onClick={() => { setPaginator({ ...paginator,page: paginator.page + 1 }) }} className='btn-sm'>View Older</AsyncButton>
                            </div>
                          )
                        }
                      />
                    </div>
                }} />
                <EntityConditional
                    entity={ selected }
                    render={ (job) => (
                        <JobDetails
                            job={ job }
                            onClose={ () => setSelected(null) }
                            fetch={ jobFetch }
                            itemKey={ (v) => v.id }
                            fields={
                                {
                                'Vendor Name':{ valueRender:(v) => v.name },
                                'SMSI Account Number':{ valueRender:(v) => v.account_number },
                                'Vendor Status':{ valueRender:(v) => v.status },
                                'Last Updated':{ valueRender:(v) => instantToDate(v.lastUpdated) },
                                'Errors':{ valueRender:(v:VendorMeta) => v.error },
                                'Result':{ valueRender:(v) => ToUpperFirst(v.syncStatus) },
                                'link':{ hideHeader:true,valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/vendor/${v.id}`) }/> },
                                //...(!vendorKinds.errors ? {} : { 'Errors':{ valueRender:(v:VendorMeta) => v.error } }),
                                //'Errors': vendorKinds.errors ? { valueRender:(v) => v.error } : undefined
                                }
                            }
                        />
                    ) }
                />

            </Fragment>
          )
        }}
      />
    </Fragment>
  )
}
export default Sync
