import { Badge } from "kdg-react";
import { Status } from "../../../Types/department";

type Props = {
  status: Status
  className?:string
}

const StatusBadge = (props: Props) => {
  const colorMap = {
    [Status.ACTIVE]: 'bg-success',
    [Status.INACTIVE]: 'bg-secondary',
    [Status.NON_POSTING]: 'bg-success-subtle',
  } satisfies { [Property in Status]: string }

  return (
    <Badge
      className={ `${colorMap[props.status]} ${props.className ? ` ${ props.className }` : ''}` }
    >
      { props.status }
    </Badge>
  )
}

export default StatusBadge
