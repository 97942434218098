import { RequestMethodArgs, TPagination } from "kdg-react"

import { Filter,Responses,LocationMeta,ESortableTableFields } from '../Types/location'
import { httpGet } from "./_common"
import { PaginatedResults } from '../Types/pagination'
import { TTableSort } from "../Hooks/sort"

export const getLocations = async (filter:Filter, job:string, paginator:TPagination,sort:TTableSort<ESortableTableFields,LocationMeta>,args: RequestMethodArgs<PaginatedResults<Responses.LocationMeta>>) => {
    await httpGet({
        url: '/api/locations',
        success: args.success,
        errorHandler: args.errorHandler,
        mapResult: (v:any) => ({
            count: v.count,
            results: v.results.map((v:Responses.LocationMeta) : LocationMeta => ({
                id:v.id,
                name:v.name,
                kind:v.kind,
            }))
        }),
        parameters:{
            search:filter.search ? filter.search : '',
            limit:paginator.numberOfItemsPerPage.toString(),
            offset:(paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
            job:job,
            sortField:sort.field,
            sortDir:sort.sortOrder,
        },
    })
}

export const fetchLocation = async (id:string,args:RequestMethodArgs<Responses.Location>) => {
    await httpGet({
        ...args,
        url:`/api/locations/${id}`
    })
}

export const syncNow = async (args:RequestMethodArgs<string>) => {
    await httpGet({
        ...args,
        url:`api/locations/sync-now`,
    })
}