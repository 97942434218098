import { useState } from 'react'
import { TextInput,AsyncButton, Row, Col, DiscriminatedUnionHandler, handleDiscriminatedUnion, Toggle } from 'kdg-react'

import Modal from '../../../Components/Modal'
import { TUserForm, initialUserForm } from '../../../Types/user'
import { Errors,ErrorMessage } from '../../../Types/error'

import { createUser, editUser } from '../../../Api/users'
import { PermissionGroupSelect } from './PermissionGroupSelect'

type Props = {
  onSave: () => void
  onClose:()=>void
  user?:TUserForm|null
}

export const AddUser = (props:Props) => {
  const [saving,setSaving] = useState(false)
  const [user, setUser] = useState<TUserForm>(
    props.user
      ? props.user
      : initialUserForm
  )

  const [errors, setErrors] = useState<Errors<TUserForm["value"]>>({})

  const save = async () => {
    setSaving(true)
    try {
      handleDiscriminatedUnion({
        value: user,
        config: {
          Create: async ({ value:v }) => {
            await createUser({
              body:v,
              success:() => {
                props.onSave()
              },
              errorHandler: async (errors) => {
                console.error('errors',errors)
                if(errors.status == 400)
                {
                  const es = (await errors.json() as ErrorMessage<TUserForm["value"]>[])
                  const t = es.reduce((agg,v) => {
                    agg[v.propertyName] = v.errorMessage
                    return agg
                  }, {} as Errors<TUserForm["value"]>)
                  setErrors(t)
                }
                setSaving(false)
              }
            })
          },
          Edit: async ({ value:v }) => {
            await editUser({
              body: v,
              success: () => {

                props.onSave()
              },
              errorHandler: async (errors) => {
                console.error('errors', errors)
                if (errors.status == 400) {
                  const es = (await errors.json() as ErrorMessage<TUserForm["value"]>[])
                  const t = es.reduce((agg, v) => {
                    agg[v.propertyName] = v.errorMessage
                    return agg
                  }, {} as Errors<TUserForm["value"]>)
                  setErrors(t)
                }
                setSaving(false)
              }
            })
          }
        }
      })
    } catch (e) {
      console.error('unable to save', e)
    } finally {
      setSaving(false)
    }
  }

  const updateUser = (prop: keyof TUserForm['value']) => (value: TUserForm['value'][typeof prop]) => setUser(prev =>
    handleDiscriminatedUnion({
      value: prev,
      config: {
        Edit: ({ value:v }) => ({
          case: 'Edit',
          value: {
            ...v,
            [prop]:value,
          }
        }),
        Create: ({ value:v }) => ({
          case: 'Create',
          value: {
            ...v,
            [prop]: value,
          }
        }),
      }
    })
  )
  return (
    <Modal
      header={() =>
        <h3>
          <DiscriminatedUnionHandler
            value={user}
            config={{
              Edit: () => 'Edit',
              Create: () => 'Create',
            }}
          /> User
        </h3>
      }
      onClose={props.onClose}
      size={'lg'}
      content={
        () => (
          <div>
            <Row fluid>
              <Col>
                <TextInput
                  placeholder={'First Name'}
                  value={user.value.firstName}
                  onChange={updateUser('firstName')}
                  label={'First Name'}
                  error={errors.firstName}
                />
              </Col>
              <Col>
                <TextInput
                  placeholder={'Last Name'}
                  value={user.value.lastName}
                  onChange={updateUser('lastName')}
                  label={'Last Name'}
                  error={errors.lastName}
                />
              </Col>
            </Row>
            <Row fluid>
              <Col>
                <TextInput
                  placeholder={'Email Address'}
                  value={user.value.email}
                  onChange={updateUser('email')}
                  label={'Email Address'}
                  error={errors.email}
                />
              </Col>
            </Row>
            <Row fluid>
              <Col>
                <TextInput
                  placeholder={'Alias'}
                  value={user.value.alias}
                  onChange={updateUser('alias')}
                  label={'Alias'}
                  error={errors.alias}
                />
              </Col>
              <Col>
                <PermissionGroupSelect
                  value={user.value.permissionGroup}
                  onChange={updateUser('permissionGroup')}
                  error={ errors.permissionGroup }
                />
              </Col>
              </Row>
              <Row>
                <Col>
                  <Toggle 
                    label = {'Deactivated'}
                    value = {user.value.status ? true : false}
                    onChange = {updateUser('status')}
                  />
                </Col>
            </Row>
            <Row>
              <Col>
                <AsyncButton onClick={save} loading={saving}>Save</AsyncButton>
              </Col>
            </Row>
          </div>
        )
      }
    />
  )
}

export default AddUser
