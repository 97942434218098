import { Option } from './util'

export enum SettingFields {
  Accounts = 'Accounts',
  Location = 'Location',
  PaymentTerms = 'Payment Terms',
  Items = 'Items'
}

export type SettingField = {
  id: string;
  name: string;
  zohoId?: Option<string>;
  intacctId?: Option<string>;
}

export namespace Response {
  export type SettingField = {
    id: string;
    name: string;
    zohoId?: Option<string>;
    intacctId?: Option<string>;
  };
  export type GetAllFieldSettings = SettingField[];
}


