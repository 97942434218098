import { RequestMethodArgs,TPagination, DateUtils } from "kdg-react"
import { EmployeeMeta,Responses,Employee } from "../Types/employee"
import { PaginatedResults } from '../Types/pagination'
import { httpGet } from "./_common"
import { tryParseDate } from '../Util/datetime'

export const getEmployees = async (search:string, paginator:TPagination, args: RequestMethodArgs<PaginatedResults<EmployeeMeta>>) => {
  await httpGet({
    url: '/api/employees',
    success: args.success,
    errorHandler: args.errorHandler,
    parameters:{
      Limit:paginator.numberOfItemsPerPage.toString(),
      Offset:(paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
      Search:search,
    },
    mapResult: (v:any) => ({
      count: v.count,
      results: v.results.map((v:Responses.EmployeeMeta) : EmployeeMeta => ({
        ...v,
        id:v.id,
        name:v.name,
        hireDate:v.hireDate ? DateUtils.LocalDate.parse(v.hireDate) : null,
      }))
    })
  })
}

export const getEmployee = async (id:string, args:RequestMethodArgs<Employee>) => {
  await httpGet({
    ...args,
    url:`/api/employees/${id}`,
    mapResult:(temp) : Employee => {
      const v : Responses.Employee = temp as any;
      return {
        ...v,
        currentCostRateDate: v.currentCostRateDate ? tryParseDate(v.currentCostRateDate) : null,
        birthDate: v.birthDate ? tryParseDate(v.birthDate) : null,
        hireDate: v.hireDate ? tryParseDate(v.hireDate) : null,
        endDate: v.endDate ? tryParseDate(v.endDate) : null,
      }
    }
  })
}