import BaseLayout from "../../Components/BaseLayout"

const DataDictionary = () => {
    const url = "https://kyledavidgroup.sharepoint.com/sites/SpringfieldMechanicalDigitalTransformation/_layouts/15/Doc.aspx?sourcedoc={8178759f-5b92-4d64-a8f2-e47846f494df}&action=embedview&wdAllowInteractivity=False&wdHideHeaders=True&wdInConfigurator=True&wdInConfigurator=True"
    return (
        <BaseLayout pageTitle={ 'Data Dictionary' }>
                <iframe className="iframe-embed-full" src={ url }></iframe>
        </BaseLayout>
    )
}

export default DataDictionary