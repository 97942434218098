import { DateUtils, RequestMethodArgs, TPagination } from "kdg-react"
import { httpGet } from "./_common"

import { PaginatedResults } from '../Types/pagination'
import { ProjectMeta,Project } from '../Types/projects'
import { TSortFields } from "../Hooks/sort"

export const getProjects = async (paginator:TPagination, search:string,sort:TSortFields,args: RequestMethodArgs<PaginatedResults<ProjectMeta>>) => {
   await httpGet({
    url: '/api/projects',
    success: args.success,
    errorHandler: args.errorHandler,
    mapResult: (v:any) => ({
      count: v.count,
      results: v.results.map((v:any) => ({
        id:v.id,
        projectName:v.project_Name,
        projectNumber:v.project_Number,
        status:v.status,
      }))
    }),
    parameters:{
      Limit:paginator.numberOfItemsPerPage.toString(),
      Offset:(paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
      Search:search,
      sortField:sort.fieldName,
      sortDir:sort.order,
    },
  })
}

export const fetchProject = async (id:string,args: RequestMethodArgs<Project>) => {
  await httpGet({
    ...args,
    url:`/api/projects/${id}`,
    mapResult:(v:any) => ({
      ...v,
      projectName: v.project_Name,
      projectNumber: v.project_Number,
      startDate:v.start_Date ? DateUtils.LocalDate.parse(v.start_Date) : null,
      endDate:v.end_Date ? DateUtils.LocalDate.parse(v.end_Date) : null,
      zohoId:v.zoho_id,
      customer:v.customer,
      customerPO:v.customer_PO,
      projectCategory:v.project_Category,
      projectType:v.project_Type,
      SMSIQuoteNumber: v.smsI_Quote_Number,
      deparment: v.department,
      parentProject: {
        id:v.parent_Project.id,
        projectName:v.parent_Project.project_Name,
        projectNumber:v.parent_Project.project_Number,
        status:v.parent_Project.status,
      },
      status:v.status,
      syncError:v.syncError
    })
  })
}
