import { PropsWithChildren } from "react"

const Tag = (props:PropsWithChildren<{}>) => {
    return (
        <div className={ 'bubble-checkbox' }>
            <label>{ props.children }</label>
        </div>
    )
}

export default Tag
