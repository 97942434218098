import { RecordActions } from './job'
import { Option } from './util'
export enum SortableFields {
    Name='Name'
}

export enum Status {
    ACTIVE='Active',
    INACTIVE='Inactive',
    NON_POSTING='Active non-posting',
  }

export type ParentMeta = {
    id:string
    crmId:Option<string>,
    intacctId:Option<string>
    zohoId:string
    name:string
}

export type DepartmentMeta = {
    id:string
    name:string
    kind:RecordActions
}

export type Department = {
    id:string,
    name:string,
    parentDepartment:Option<ParentMeta>
    crmId:string,
    intacctId:Option<string>
    zohoId:Option<string>
    error:Option<string>
    salesEmail:Option<string>
    timesheetEmail:Option<string>
    accountingEmail:Option<string>
    status:Option<Status>
}

export namespace Responses {
    export type ParentMeta = {
        id:string
        crmId:Option<string>,
        intacctId:Option<string>
        zohoId:string
        name:string
    }
    export type DepartmentMeta = {
        id:string
        name:string
        kind:RecordActions
    }
    export type Department = {
        id:string,
        status:Option<Status>
        name:string,
        parentDepartment:Option<ParentMeta>
        crmId:string,
        intacctId:Option<string>
        zohoId:Option<string>
        error:Option<string>
        salesEmail:Option<string>
        timesheetEmail:Option<string>
        accountingEmail:Option<string>
    }
}