import React from 'react';
import { useAppNavigation } from '../Hooks/hooks';

type LinkProps = {
  url: string,
  className?: string,
  children: React.ReactNode,
};

const Link: React.FC<LinkProps> = ({ url, className, children }) => {
  const navigate = useAppNavigation();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    navigate(url);
  };

  return (
    <a href={url} className={className} onClick={handleClick}>
      {children}
    </a>
  );
};

export default Link;

