import { RequestMethodArgs,PostRequestMethodArgs } from "kdg-react"
import { httpPost } from "./_common"
import { User,UserCreateForm,UserEditForm, UserType,UserFilter } from '../Types/user'

export const getUsers = async (search:string,type:UserType[],filter:UserFilter,args: RequestMethodArgs<User[]>) => {
  await httpPost({
    url: '/api/user',
    success: args.success,
    errorHandler: args.errorHandler,
    body:{ ...filter,search:search,type }
  })
}

export const createUser = async (args: PostRequestMethodArgs<UserCreateForm, string>) => {
  await httpPost({
    url: `/api/user/create`,
    body: {
      firstName:args.body.firstName || null,
      lastName:args.body.lastName || null,
      alias:args.body.alias || null,
      email:args.body.email || null,
      permissionGroup: args.body.permissionGroup || null,
      status:args.body.status,
    },
    success: args.success,
    errorHandler: args.errorHandler,
  })
}

export const editUser = async (args: PostRequestMethodArgs<UserEditForm, string>) => {
  await httpPost({
    url: `/api/user/edit`,
    body: {
      id: args.body.id || null,
      firstName: args.body.firstName || null,
      lastName: args.body.lastName || null,
      alias: args.body.alias || null,
      email: args.body.email || null,
      permissionGroup: args.body.permissionGroup || null,
      status: args.body.status,
    },
    success: args.success,
    errorHandler: args.errorHandler,
  })
}
