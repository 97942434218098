import { Option } from './util'
import { SyncStatus } from './sync'

export enum ESortableTableFields {
    Name='Name',
}

export type Filter = {
    search:Option<string>
}

export type Location = {
    id:string
    name:string
    intacctId:string
    zohoId:Option<string>
    syncError:Option<string>
}

export type LocationMeta = {
    id:string
    name:string
    kind:SyncStatus
}

export namespace Responses {
    export type Location = {
        id:string
        name:string
        intacctId:string
        zohoId:Option<string>
        syncError:Option<string>
    }
    export type LocationMeta = {
        id:string
        name:string
        kind:SyncStatus
    }
}