import { ActionButton } from 'kdg-react'

import Icon, { ICON_KIND } from '../Icon'

type Props = {
    onClick:() => void
    className?:string
}

const SyncButton = (props:Props) => {
    return (
        <ActionButton onClick={ props.onClick } className={ 'd-flex'.concat(props.className ?? '') }> <Icon className='me-2' size={ 'xl' } kind={ ICON_KIND.Sync } /> Sync Now</ActionButton>
    )
}

export default SyncButton
