import { Col, Row, Card, ActionButton, DiscriminatedUnionHandler, Loader, Table, EntityConditional,Conditional,TPagination } from 'kdg-react'
import { useCallback, useEffect, useState,Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { getVendor,getVendorJobs } from '../../Api/vendors'
import BaseLayout from "../../Components/BaseLayout"
import Tooltip from '../../Components/Tooltip'
import Icon, { ICON_KIND } from '../../Components/Icon'
import { useAppNavigation } from '../../Hooks/hooks'
import { ROUTE_PATH } from '../../Routing/Routing'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { vendorAddressDisplay, Vendor } from '../../Types/vendor'
import { VendorStatusBadge } from './Components/VendorStatusBadge'
import List from '../../Components/List'
import { getDirections } from '../../Types/sync'
import { formatInstant } from '../../Util/datetime'
import { PaginatedResults } from '../../Types/pagination'
import { RecordJob } from '../../Types/job'
import { ToUpperFirst } from '../../Util/string'
import Field from './Components/Field'

const VendorPage = () => {
  const navigate = useAppNavigation()
  const [vendor, setVendor] = useState<TLoadedData<Vendor>>(defaultLoadedData)
  const [jobs, setJobs] = useState<TLoadedData<PaginatedResults<RecordJob>>>(defaultLoadedData)
  const [paginator,setPaginator] = useState<TPagination>({ numberOfItemsPerPage:8,page:1 })
  const [previous,setPrevious] = useState<RecordJob[]>([])
  const params = useParams<{id:string}>()

  const handleLoadJobs = useCallback(async() => {
    setJobs(defaultLoadedData)
    await getVendorJobs(
      params.id || 'not-found',
      paginator,
      {
        success: (v) => {
          const all = previous.concat(v.results)
          setPrevious(all)
          setJobs({
            case: TLoadedCase.LOADED,
            value: { ...v, results:all },
          })
        },
        errorHandler: () => setJobs({
          case: TLoadedCase.ERROR,
          value: 'unable to load Jobs'
        })
      }
    )
  }, [paginator])

  const handleLoadVendor = useCallback(async () => {
    await getVendor(
      params.id || 'not-found', // not-found will hit our error handler
      {
        success: v => setVendor({
          case: TLoadedCase.LOADED,
          value: v,
        }),
        errorHandler: () => setVendor({
          case: TLoadedCase.ERROR,
          value: 'unable to load Vendor'
        })
      }
    )
  }, [])

  useEffect(() => {
    handleLoadJobs()
  }, [paginator])

  useEffect(() => {
    handleLoadVendor()
  }, [handleLoadVendor])


  return (
    <BaseLayout pageTitle={'Vendors'}>
      <DiscriminatedUnionHandler
        value={vendor}
        config={{
          [TLoadedCase.LOADING]: () => <Loader />,
          [TLoadedCase.ERROR]: e => e,
          [TLoadedCase.LOADED]: ({ value:v }) => {
            return (
              <Row fluid className='mb-5'>
                <Col md={8} lg={9}>
                  <EntityConditional
                    entity={ v.syncError }
                    render={
                      (v) => (
                        <div className="alert alert-danger show d-flex justify-content-between align-items-center" role="alert">
                          <h3>
                            <Icon className='me-2' kind={ ICON_KIND.Ban } />
                            Incoming Sync Error: { v }
                          </h3>
                          {/*<button className='btn btn-danger'>Review and Resolve</button>*/}
                        </div>
                      )
                    }
                  />
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className='d-flex'>
                          <ActionButton
                            className='btn-light me-3'
                            onClick={() => navigate(ROUTE_PATH.VENDORS)}
                          >
                            <Icon kind={ICON_KIND.ArrowLeft}/>
                          </ActionButton>
                          <div>
                            <div className="d-flex align-items-center">
                              <h1>{v.name}</h1>
                              <EntityConditional
                                entity={v.status}
                                render={status => (
                                  <VendorStatusBadge
                                    status={status}
                                    className={'rounded-pill text-white ms-2 text-bg-success'}
                                  />
                                )}
                              />
                            </div>
                            <h4 className='text-muted'>{v.account_number}</h4>
                          </div>
                        </div>
                        <div>
                          <div>
                            <Tooltip content={ "Not Implemented" }>
                              <a
                                href={``}
                                target={'_blank'}
                              >View in Intacct</a>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-4">General</h6>
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col md={6}>
                          <Field label={ 'Full Name' } value={ v.name }/>
                          <Field label={ '"Print As"' } value={ v.print_as } />
                          <Field label={ 'Address' } value={ vendorAddressDisplay(v.address) } />
                          <Field label={ 'Tax ID' } value={ v.taxId } />
                        </Col>
                        <Col md={6}>
                          <Field label={ 'Phone' } value={ v.phone } />
                          <Field label={ 'Fax' } value={ v.fax } />
                          <Field label={ 'Creator ID' } value={ v.zohoId } />
                          <Field label={ 'Intacct ID' } value={ v.intacctId } />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <h6 className="mt-4">Payment Details</h6>
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col md={6}>
                          <Field label={ 'Payment Terms' } value={ v.payment_terms ? v.payment_terms.name : null } />
                        </Col>
                        <Col md={6}>
                          <Field label={ 'Vendor Billing Type' } value={ v.billing_type } />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <h6 className="mt-4">Contacts</h6>
                  <div className="card">
                    <div className="card-body">
                      <Table
                        items={v.vendor_contacts} // TODO
                        itemKey={v => v.name || 'unknown name' }
                        fields={{
                          'Contact': {
                            valueRender:x => x.name,
                          },
                          'Category': {
                            valueRender:x => x.category,
                          },
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={3}>
                  <Card
                    className='mb-3'
                    body={{
                      className: '',
                      content:(
                        <DiscriminatedUnionHandler
                          value={jobs}
                          config={ {
                            [TLoadedCase.LOADING]: () => <Loader />,
                            [TLoadedCase.ERROR]: e => e,
                            [TLoadedCase.LOADED]: ({ value:data }) => {
                              return (
                                <div>
                                  <List
                                    className={ 'history-log' }
                                    items={ data.results }
                                    extractKey={ (v) => `${v.jobId}-${v.recordId}` }
                                    itemClass={ 'clickable' }
                                    renderItem={
                                      (v) => {
                                        const direction = getDirections(v.jobName)
                                        //show created,updated and error. 
                                        return (
                                          <Fragment>
                                            <div
                                              className={ `d-flex justify-content-between align-items-center mb-1 ${v.error ? 'fail-sync' : 'success-sync'}` }
                                            >
                                              <p className="pe-3">{ direction.from } <Icon kind={ ICON_KIND.ArrowRight } /> { direction.to }{direction.entity &&  <span className='text-muted'>: {direction.entity}</span> } </p>
                                              <p className="timestamp text-muted small">{ formatInstant(v.occurred) }</p>
                                            </div>
                                            <div className="ps-3 border-start">
                                              <p className="small text-muted mb-0">{ ToUpperFirst(v.kind) }</p>
                                            </div>
                                          </Fragment>
                                        )
                                      }
                                    }
                                  />
                                  <Conditional
                                    condition={ data.count > previous.length }
                                    onTrue={
                                      () => (
                                        <div className='d-flex justify-content-center py-2'>
                                          <ActionButton onClick={ () => setPaginator({ ...paginator,page: paginator.page + 1 }) } className='btn-sm'>View Older</ActionButton>
                                        </div>
                                      )
                                    }
                                  />
                                </div>
                              )
                              
                            }
                          } }
                        />
                        )
                    }}
                  />
                </Col>
              </Row>
            )
          }
        }}
      />
    </BaseLayout>
  )
}

export default VendorPage
