import { httpGet, httpPost } from "./_common"
import { RequestMethodArgs, TPagination,PostRequestMethodArgs } from "kdg-react"
import { PaginatedResults } from '../Types/pagination'
import { AlertEmail,Responses, TAlertEmailForm } from "../Types/alertEmail"
import { Option } from '../Types/util'

export const fetchAlertEmails = async (paginator: TPagination, search:string, job:Option<string>,args: RequestMethodArgs<PaginatedResults<AlertEmail>>) => {
    await httpGet({
      url: '/api/alertEmail',
      success: args.success,
      errorHandler: args.errorHandler,
      mapResult: (b: unknown) => {
        var a  = b as PaginatedResults<Responses.AlertEmail>
        return ({
          count: a.count,
          results: a.results
        })
    },
      parameters: {
        Limit: paginator.numberOfItemsPerPage.toString(),
        Offset: (paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
        Search: search,
        job:job ? job : '',
      },
    });
};

export const createAlertEmail = async (args: PostRequestMethodArgs<TAlertEmailForm, string>) => {
    await httpPost({
        ...args,
        url:`/api/alertEmail/create`,
    })
}

export const updateAlertEmail = async (args: PostRequestMethodArgs<TAlertEmailForm, string>) => {
    await httpPost({
        ...args,
        url:`api/alertEmail/update/${args.body.id}`
    })
}