import { useState,useEffect } from 'react'
import { DiscriminatedUnionHandler,Loader,Pagination,Card,Row,Col,DelayedTextInput,Enums,useNotification,EntityConditional,TPagination,RequestMethodArgs,SortOrder } from 'kdg-react'

import BaseLayout from '../../Components/BaseLayout'
import Table from '../../Components/TableWithNoData'
import SyncButton from '../../Components/Buttons/Sync'
import Sync from '../../Components/Sync'
import JobDetails from '../../Components/JobDetails'
import ModuleSyncDirection from '../../Components/ModuleSyncDirection'

import Icon, { ICON_KIND } from '../../Components/Icon'

import { getLocations,syncNow } from '../../Api/locations'
import { ESortableTableFields, Filter,LocationMeta,Responses } from '../../Types/location'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { useAppNavigation,usePagination } from '../../Hooks/hooks'
import { paginationOptions } from '../../Types/pagination'
import { JobKinds,Job } from '../../Types/job'
import { FetchJobKind } from '../../Api/jobs'
import { PaginatedResults } from '../../Types/pagination'
import { IntacctToCreator, SyncStatus } from '../../Types/sync'
import { ToUpperFirst } from '../../Util/string'
import { useTableSort,defaultSort } from '../../Hooks/sort'

const initialSort = {
    field:ESortableTableFields.Name,
    sort:defaultSort,
    sortOrder:SortOrder.ASC,
}

const Locations = () => {
    const [filter,setFilter] = useState<Filter>({ search:null })
    const [locations, setLocations] = useState<TLoadedData<LocationMeta[]>>(defaultLoadedData)
    const [total,setTotal] = useState(0)
    const [job,setJob] = useState<Job|null>(null)

    const navigate = useAppNavigation()
    const { notify } = useNotification()
    const paginator = usePagination({ numberOfItemsPerPage:10,page:1 })

    const fetch = async () => {
        setLocations(defaultLoadedData)
        await getLocations(filter,'',paginator.pagination,tableSort,{
            success:(v) => {
                setTotal(v.count)
                setLocations({ case:TLoadedCase.LOADED,value:v.results })
            }
        })
    }

    const { tableSort, setTableSort } = useTableSort<ESortableTableFields,LocationMeta>({
        mapping:{
            [ESortableTableFields.Name]:'name',
        },
        initialState:initialSort,
    })

    const parseTableField = (x:string):ESortableTableFields => {
        switch (x) {
            case ESortableTableFields.Name:
            return x
          default:
            throw new Error(`unable to parse table field: ${x}`)
        }
      }

    const jobFetch = async (job:string,paginator:TPagination,args: RequestMethodArgs<PaginatedResults<Responses.LocationMeta>>) => {
        await getLocations({ search:null },job,paginator,initialSort,args)
    }

    const sync = () => {
        syncNow({
            success:() => {
                notify({
                    key:new Date().getUTCMilliseconds(),
                    message:'Sync Successfully Triggered',
                    type:Enums.Color.Success,
                    autoDismiss:3000
                })
            }
        })
    }

    useEffect(() => {
        fetch()
    },[filter.search,paginator.pagination.numberOfItemsPerPage,paginator.pagination.page,tableSort.field,tableSort.sortOrder])

    return (
        <BaseLayout pageTitle={ 'Locations' }>
            <Row fluid>
                <Col md={ 8 } lg={ 9 }>
                    <Card
                        header={ {
                            content:
                                <div className="d-flex justify-content-between w-100">
                                    <div className="d-flex">
                                        <DelayedTextInput
                                            placeholder="Search..."
                                            value={ filter.search }
                                            delay={ 300 }
                                            onDelay={
                                            (v) => {
                                                setFilter((prev) => ({ ...prev,search:v }))
                                                paginator.reset()
                                            }
                                            }
                                        />
                                    </div>
                                    <SyncButton onClick={ sync } />
                                </div>
                        } }
                        body={ {
                            content:<DiscriminatedUnionHandler
                                value={ locations }
                                config={ {
                                    [TLoadedCase.LOADING]: () => <Loader />,
                                    [TLoadedCase.ERROR]: e => e.value,
                                    [TLoadedCase.LOADED]:({ value:v }) => (
                                        <Table
                                            noDataMessage={ 'There are no locations to display.' }
                                            footer={
                                                <Pagination pagination={ paginator.pagination } totalRecords={ total } pageOptions={ paginationOptions } onChange={ paginator.update } optionsDirection="top" />
                                            }
                                            headerClassName='table-light'
                                            className={ 'table-hover row-border' }
                                            onSort={
                                                (v) => setTableSort({
                                                    field:parseTableField(v.field.toString()),
                                                    sort:v.sort.sort,
                                                    sortOrder:v.sort.order,
                                                })
                                            }
                                            defaultSort={
                                                tableSort ? {
                                                    field:tableSort.field,
                                                    sort:{
                                                        sort:tableSort.sort,
                                                        order:tableSort.sortOrder,
                                                    }
                                                } : undefined
                                            }
                                            extractRowClassname={ (v) => v.kind  == SyncStatus.ERROR ? 'error' : 'success' }
                                            items={ v }
                                            itemKey={ (v) => v.id }
                                            fields={
                                                {
                                                    [ESortableTableFields.Name]: { valueRender:(v) => v.name,sort:{ sort:defaultSort,order:SortOrder.ASC } },
                                                    'Actions': { hideHeader:true,valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/locations/${v.id}`) } /> }
                                                }
                                            }
                                        />
                                    )
                                } }
                            />
                        } }
                    />
                </Col>
                <Col md={4} lg={3}>
                    <Card
                        className='mb-2'
                        body={ {
                            className: '',
                            content:
                                <ModuleSyncDirection  direction={ IntacctToCreator } />
                        } }
                    />
                    <Sync
                        jobs={ [JobKinds.LOCATIONS_PULL,JobKinds.LOCATIONS_SEND] }
                        kind={ FetchJobKind.LOCATIONS }
                        onItemSelect={ (v) => setJob(v) }
                    />
                    <EntityConditional
                        entity={ job }
                        render={ (v) => (
                            <JobDetails
                                job={ v }
                                onClose={ () => setJob(null) }
                                fetch={ jobFetch }
                                itemKey={ (v) => v.id }
                                fields={
                                    {
                                        'Name': { valueRender:(v) => v.name },
                                        'Result': { valueRender:(v) => ToUpperFirst(v.kind) },
                                        'Actions': { hideHeader:true,valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/locations/${v.id}`) } /> },
                                    }
                                }
                            />
                        ) }
                    />
                </Col>
            </Row>
        </BaseLayout>
    )
}

export default Locations
