import { useState, useEffect, useCallback } from 'react'
import { DiscriminatedUnionHandler, Loader, Card, Row, Col, DelayedTextInput, EntityConditional, Pagination, useNotification,Enums,DateRange,TPagination,RequestMethodArgs,SortOrder } from 'kdg-react'
import { LocalDate } from '@js-joda/core'

import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { BillMeta,SortableFields } from '../../Types/bills'

import Icon, { ICON_KIND } from '../../Components/Icon'
import { getBills,syncNow } from '../../Api/bills'
import BaseLayout from '../../Components/BaseLayout'
import { useAppNavigation, usePagination } from "../../Hooks/hooks"
import Table from '../../Components/TableWithNoData'
import Sync from '../../Components/Sync'
import { FetchJobKind } from '../../Api/jobs'
import ModuleSyncDirection from '../../Components/ModuleSyncDirection'
import { CreatorToIntacct } from '../../Types/sync'
import { Option } from '../../Types/util'
import { Job,RecordActions,Entities,JobKinds } from '../../Types/job'
import { paginationOptions } from '../../Types/pagination'
import { ROUTE_PATH } from '../../Routing/Routing'
import Link from '../../Components/Link'
import { formatDate } from '../../Util/datetime'
import Filter from '../../Components/Filter'

import JobDetails from '../../Components/JobDetails'
import SyncButton from '../../Components/Buttons/Sync'
import SyncStatusCheckboxes, { SyncStatuses } from '../../Components/Form/SyncStatusCheckboxes'
import { PaginatedResults } from '../../Types/pagination'
import { ToUpperFirst } from '../../Util/string'
import { useTableSort,defaultSort } from '../../Hooks/sort'

type Filter = {
    dueDateStart:LocalDate|null
    dueDateEnd:LocalDate|null
}

const initialSort = {
    field:SortableFields.DUE_DATE,
    sort:defaultSort,
    sortOrder:SortOrder.ASC,
}

const Bills = () => {
  const [bills, setBills] = useState<TLoadedData<BillMeta[]>>(defaultLoadedData)
  const [job, setJob] = useState<Option<Job>>(null)
  const [total,setTotal] = useState(0)
  const [search,setSearch] = useState("")
  const paginator = usePagination({ numberOfItemsPerPage:10,page:1 })
  const navigate = useAppNavigation()
    const { notify } = useNotification()
    const [status,setStatus] = useState([SyncStatuses.SUCCESSFUL,SyncStatuses.ERROR])
    const [filter,setFilter] = useState<Filter>({ dueDateStart:null,dueDateEnd:null })
    const { tableSort, setTableSort,mappedSortFields } = useTableSort<SortableFields,BillMeta>({
        mapping:{
            [SortableFields.INVOICE_NUMBER]:'invoiceNumber',
            [SortableFields.DUE_DATE]:'dueDate',
            [SortableFields.TRANSACTION_DATE]:'transactionDate',
        },
        initialState:initialSort,
    })

  const fetch = useCallback(async () => {
    setBills(defaultLoadedData)
    getBills(paginator.pagination,search, null, status, filter.dueDateStart,filter.dueDateEnd,mappedSortFields,{
      success:(v) => {
        setBills({ case:TLoadedCase.LOADED,value:v.results })
        setTotal(v.count)
      },
      errorHandler:(error) => {
        setBills({ case:TLoadedCase.ERROR,value:'cannot load bills' })
        console.error('error',error)
      }
    } )
  },[paginator.pagination.page,paginator.pagination.numberOfItemsPerPage,search,status,filter.dueDateStart,filter.dueDateEnd,tableSort.field,tableSort.sortOrder])

    const jobFetch = async (job:string,paginator:TPagination,args: RequestMethodArgs<PaginatedResults<BillMeta>>) => {
        await getBills(paginator,'',job,[SyncStatuses.SUCCESSFUL,SyncStatuses.ERROR],null,null,mappedSortFields,args)
    }

    const parseTableField = (x:string):SortableFields => {
        switch (x) {
            case SortableFields.INVOICE_NUMBER:
            case SortableFields.DUE_DATE:
            case SortableFields.TRANSACTION_DATE:
                return x
          default:
                throw new Error(`unable to parse table field: ${x}`)
        }
    }

  useEffect(() => {
    fetch()
  },[fetch])

    const triggerSync = () => {
        syncNow({
            success:(_) => {
                notify({
                    key:new Date().getUTCMilliseconds(),
                    message:'Sync Successfully Triggered',
                    type:Enums.Color.Success,
                    autoDismiss:3000
                })
            }
        })
    }

  return (
    <BaseLayout
      pageTitle={ 'Bills' }
    >
      <Row fluid>
        <Col md={ 8 } lg={ 9 }>
          <Card
            header={ {
              className:'d-flex align-items-center',
              content:
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex">
                    <div className="d-flex align-items-center me-3">
                      <DelayedTextInput
                        placeholder="Search..."
                        value={ search }
                        delay={ 300 }
                        onDelay={
                          (v) => {
                            setSearch(v || "")
                            paginator.reset()
                          }
                        }
                      />
                    </div>
                    <SyncStatusCheckboxes
                        value={ status }
                        onChange={ (v) => {setStatus(v); paginator.reset() }}
                    />
                     <Filter
                        filter={ filter }
                        onChange={ (v) => { setFilter(v); paginator.reset() }}
                        form={
                            (filter,setFilter) => {
                                return (
                                    <DateRange
                                        start={ filter.dueDateStart }
                                        end={ filter.dueDateEnd }
                                        onEndChange={ (v) => setFilter((prev) => ({ ...prev,dueDateEnd:v })) }
                                        onStartChange={ (v) => setFilter((prev) => ({ ...prev,dueDateStart:v })) }
                                        label={ 'Due Date' }
                                    />
                                )
                            }
                        }
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Link
                      url={ ROUTE_PATH.BILL_SYNC }
                      className={ 'mx-3' }
                    >
                      View mapped fields
                    </Link>

                    <SyncButton onClick={ triggerSync } />
                  </div>
                </div>

            } }
            body={ {
              content:<DiscriminatedUnionHandler
                value={ bills }
                config={ {
                  [TLoadedCase.LOADING]: () => <Loader />,
                  [TLoadedCase.ERROR]: e => e.value,
                  [TLoadedCase.LOADED]:({ value:v }) => (
                    <Table
                      noDataMessage={ 'There are no bills to display.' }
                      footer={
                        <Pagination pagination={ paginator.pagination } totalRecords={ total } pageOptions={ paginationOptions } onChange={ paginator.update } optionsDirection='top'/>
                      }
                      headerClassName='table-light'
                      className={ 'table-hover row-border mb-0' }
                      extractRowClassname={ (v) => v.status == RecordActions.ERROR ? 'error' : 'success' }
                      items={ v }
                      onSort={
                            (v) => setTableSort({
                                field:parseTableField(v.field.toString()),
                                sort:v.sort.sort,
                                sortOrder:v.sort.order,
                            })
                        }
                        defaultSort={
                            tableSort ? {
                                field:tableSort.field,
                                sort:{
                                    sort:tableSort.sort,
                                    order:tableSort.sortOrder,
                                }
                            } : undefined
                        }
                      itemKey={ (v) => v.id }
                      fields={
                        {
                          [SortableFields.INVOICE_NUMBER]: { valueRender:(v) => v.invoiceNumber,sort:{ sort:defaultSort,order:SortOrder.ASC } },
                          [SortableFields.DUE_DATE]: { valueRender:(v) => v.dueDate ? formatDate(v.dueDate) : '' ,sort:{ sort:defaultSort,order:SortOrder.ASC }},
                          [SortableFields.TRANSACTION_DATE]: { valueRender:(v) => v.transactionDate ? formatDate(v.transactionDate) : '',sort:{ sort:defaultSort,order:SortOrder.ASC } },
                          'link':{ hideHeader:true,valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/bill/${v.id}`) }/> },
                        }
                      }
                    />
                  )
                } }
              />
            } }
          />
        </Col>
        <Col md={4} lg={3}>
        <Card
            className='mb-2'
            body={{
              className: '',
              content:
                <ModuleSyncDirection  direction={ CreatorToIntacct } entity={ Entities.SMSI }/>
            }}
          />
          <Sync
            jobs={ [JobKinds.PULL_BILLS,JobKinds.SEND_BILLS] }
            kind={ FetchJobKind.BILLS }
            onItemSelect={ (v) => setJob(v) }
          />
          <EntityConditional
            entity={ job }
            render={ (v) => (
                <JobDetails
                    job={ v }
                    onClose={ () => setJob(null) }
                    fetch={ jobFetch }
                    itemKey={ (v) => v.id }
                    fields={
                      {
                        'Invoice Number': { valueRender:(v) => v.invoiceNumber },
                        'Due Date': { valueRender:(v) => v.dueDate ? formatDate(v.dueDate) : '' },
                        'Transaction Date': { valueRender:(v) => v.transactionDate ? formatDate(v.transactionDate) : '' },
                        'Result':{ valueRender:(v) => ToUpperFirst(v.status) },
                        'link':{ hideHeader:true,valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/bill/${v.id}`) }/> },
                      }
                    }
                />
            ) }
          />

        </Col>
      </Row>
    </BaseLayout>
  )
}

export default Bills
