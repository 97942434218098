import { Conditional, Loader, Select } from "kdg-react"
import { useCallback, useContext, useEffect, useState } from "react"
import { getPermissionGroups } from "../../../Api/auth"
import { AuthContext } from "../../../Context/AuthContext"
import { PermissionGroup } from "../../../Types/permission"

type Props = {
  onChange: (_: PermissionGroup | null) => void
  value: PermissionGroup | null
  disabled?: boolean
  error?:string
}

export const PermissionGroupSelect = (props:Props) => {
  const [groups, setGroups] = useState<PermissionGroup[]>([])
  const [loading, setLoading] = useState(true)

  const auth = useContext(AuthContext)
  console.log(auth)

  const handleLoadGroups = useCallback(async () => {
    try {
      await getPermissionGroups({
        success: groups => setGroups(groups),
        errorHandler: x => console.error(x),
      })
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    handleLoadGroups()
  }, [])

  return (
    <Conditional
      condition={loading}
      onTrue={() => <Loader />}
      onFalse={() => (
        <Select
          label={'Permission Group'}
          options={groups}
          value={props.value}
          parseKey={x => x}
          parseOptionLabel={x => x}
          onChange={group => props.onChange(group)}
          error={props.error}
        />
      )}
    />
  )
}