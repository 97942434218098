import { useState, useEffect } from 'react'
import { DiscriminatedUnionHandler, Loader, Pagination,RequestMethodArgs, TPagination } from 'kdg-react'

import Modal from '../Components/Modal'
import Table from '../Components/TableWithNoData'

import Icon, { ICON_KIND } from '../Components/Icon'

import { Job } from '../Types/job'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../Types/util'
import { usePagination } from '../Hooks/hooks'
import { formatInstant } from '../Util/datetime'
import { paginationOptions,PaginatedResults } from '../Types/pagination'
import { getDirections } from '../Types/sync'

type Props<T extends {}> = {
  job: Job
  onClose: () => void
  fetch:(_:string,paginator:TPagination,args: RequestMethodArgs<PaginatedResults<T>>) => Promise<void>
} & Pick<React.ComponentProps<typeof Table<T>>, 'fields'|'itemKey'|'extractRowClassname'>;

const JobDetails = <T extends {},>(props: Props<T>) => {
  const [details, setDetails] = useState<TLoadedData<T[]>>(defaultLoadedData)
  const [total,setTotal] = useState(0)
  const pagination = usePagination({ page: 1, numberOfItemsPerPage: 10 })

  const fetch = async () => {
    setDetails(defaultLoadedData)
    await props.fetch(props.job.id,pagination.pagination,{
        success:(v) => {
            setTotal(v.count)
            setDetails({
                case:TLoadedCase.LOADED,
                value:v.results
            })
        }
    })
    // {
    //   success:(x) => {
    //     setDetails({ case:TLoadedCase.LOADED, value: x.results })
    //     setTotal(x.count)
    //   },
    //   errorHandler:() => {
    //     setDetails({ case:TLoadedCase.ERROR, value: 'Cannot load bills' })
    //   }
    // });
  };

  useEffect(() => {
    fetch();
  }, [props.job, pagination.pagination]);

  const job = props.job;

  return (
    <Modal
      size='xl'
      onClose={ props.onClose }
      header={
        () => {
          const destination = getDirections(job.name);
          return (
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <h3>{ destination.from } <Icon kind={ ICON_KIND.ArrowRight } /> { destination.to }</h3>
                <p className="timestamp text-muted small">{ formatInstant(job.start) }</p>
              </div>
              <div className='d-flex  p-3 border'>
                <div className='px-2'>
                  <Icon kind={ ICON_KIND.Add } /> { job.created } Addition
                </div>
                <div className='px-2'>
                  <Icon kind={ ICON_KIND.Restore } /> { job.updated } Update
                </div>
                <div className='px-2'>
                  <Icon kind={ ICON_KIND.Ban } /> { job.errors } Error
                </div>
              </div>
            </div>
          );
        }
      }
      content={
        () => (
          <DiscriminatedUnionHandler
            value={ details }
            config={ {
              [TLoadedCase.LOADING]: () => <Loader />,
              [TLoadedCase.ERROR]: e => e,
              [TLoadedCase.LOADED]: ({ value:v }) => (
                <Table
                  noDataMessage={ 'There are no details to display.' }
                  footer={ <Pagination pagination={ pagination.pagination } totalRecords={ total } pageOptions={ paginationOptions } onChange={ pagination.update } /> }
                  headerClassName='table-light'
                  className={ 'table-hover row-border' }
                  extractRowClassname={ props.extractRowClassname }
                  items={ v }
                  itemKey={ props.itemKey }
                  fields={ props.fields }
                />
              )
            } }
          />
        )
      }
    />
  );
};

export default JobDetails;