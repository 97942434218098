import { DiscriminatedUnion, Union } from "kdg-react"
import { Permission, PermissionGroup } from "./permission"

export type UserMeta = {
  id: string
  email: string
  firstName: string
  lastName: string | null
  status: boolean
}

export type UserFilter = {
    roles:PermissionGroup[]
}

export enum UserType {
    ACTIVE='active',
    INACTIVE='inactive',
}

export type User = {
  alias: string | null
  permissionGroup: PermissionGroup
  permissions: Permission[]
} & UserMeta

export type UserCreateForm = {
  [Property in keyof Omit<User,
    | "id"
    | "permissions"
  >]: User[Property] | null
}
export type UserEditForm = {
  [Property in keyof User]: User[Property] | null
}

export type TUserForm = DiscriminatedUnion<[
  Union<"Create", UserCreateForm>,
  Union<"Edit", UserEditForm>,
]>

export const userToUserForm = (x: User): TUserForm => ({
  case: 'Edit',
  value:x,
})

export const initialUserForm: TUserForm = {
  case: 'Create',
  value: {
    firstName: null,
    lastName: null,
    alias: null,
    email: null,
    permissionGroup: null,
    status:false,
  }
}