import { RequestMethodArgs,TPagination } from "kdg-react"
import { httpGet,httpPost } from "./_common"

import { Vendor, VendorStatus,VendorMeta, VendorContact, Filter } from '../Types/vendor'
import { RecordJob } from '../Types/job'
import { PaginatedResults } from '../Types/pagination'
import { parseInstant } from "../Util/datetime"
import { mapRecordJob } from './jobs'
import { TSortFields } from "../Hooks/sort"


const tryParseVendorStatus = (x: string) => {
  switch (x.toLowerCase()) {
    case "active":
      return VendorStatus.ACTIVE
    case "inactive":
      return VendorStatus.INACTIVE
    case "do not use":
      return VendorStatus.DO_NOT_USE
    case "see accounting":
      return VendorStatus.SEE_ACCOUNTING
    default:
      throw `unable to parse vendor status from: ${x}`
  }
}

const mapVendor = (v: any): Vendor => ({
  id: v.id,
  account_number: v.account_number || null,
  intacctId: v.intacct_id || null,
  zohoId: v.zoho_id || null,
  name: v.name,
  print_as: v.print_As,
  status: v.status ? tryParseVendorStatus(v.status) : null,
  phone: v.vendor_Phone || null,
  fax: v.vendor_FAX || null,
  website: v.website || null,
  billing_type: v.vendor_Billing_Type || null,
  payment_terms: v.payment_Terms || null,
  address: {
    address_1: v.vendor_Address_1 || null,
    address_2: v.vendor_Address_2 || null,
    city: v.vendor_City || null,
    state: v.vendor_State || null,
    zip: v.vendor_Zip || null,
    country: v.vendor_Country || null,
  },
  contact: v.vendor_Contact || null,
  vendor_contacts:(v.vendorContacts || []).map((v:any) : VendorContact => ({ name:v.name, category:v.category })),
  syncError:v.syncError,
  taxId:v.tax_ID_Number,
})

export const getVendors = async (search:string,job:string,syncStatus:string[],filters:Filter,paginator:TPagination, sort:TSortFields,args: RequestMethodArgs<PaginatedResults<VendorMeta>>) => {
  await httpPost({
    url: '/api/vendors',
    success: args.success,
    errorHandler: args.errorHandler,
    mapResult: (v:any) => ({
      count: v.count,
      results: v.results.map((v:any) : VendorMeta => ({
        id:v.id,
        name:v.name,
        account_number:v.account_number,
        status:v.status,
        lastUpdated:parseInstant(v.lastUpdated),
        syncStatus:v.syncStatus,
        error:v.error,
      }))
    }),
    body:{
      search:search,
      Limit:paginator.numberOfItemsPerPage.toString(),
      Offset:(paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
      syncStatus:syncStatus,
      filters:filters,
      job:job,
      sortField:sort.fieldName,
      sortDir:sort.order,
    },
  })
}

export const getVendor = async (id: string, args: RequestMethodArgs<Vendor>) => {
  await httpGet({
    url: `/api/vendors/${id}`,
    success: args.success,
    errorHandler: args.errorHandler,
    mapResult:mapVendor,
  })
}

export const getVendorJobs = async (id:string,paginator:TPagination,args:RequestMethodArgs<PaginatedResults<RecordJob>>) => {
  await httpGet({
    url:`/api/vendors/${id}/history`,
    success:args.success,
    errorHandler:args.errorHandler,
    parameters:{
      Limit:paginator.numberOfItemsPerPage.toString(),
      Offset:(paginator.numberOfItemsPerPage * (paginator.page - 1)).toString(),
    },
    mapResult:(v:any) => ({
      count:v.count,
      results:v.results.map(mapRecordJob),
    })
  })
}

export const syncNow = async (args:RequestMethodArgs<string>) => {
    await httpGet({
        ...args,
        url:`api/vendors/sync-now`,
    })
}
