import { composeAppRoutingProvider, Conditional, RouteType } from 'kdg-react'
import { useContext } from 'react'
import { AuthContext } from '../Context/AuthContext'
import Vendors from '../Views/Vendors/Vendors'
import Vendor from '../Views/Vendors/Vendor'
import Login from '../Views/Login/Login'
import ScheduledTasks from '../Views/ScheduledTasks/ScheduledTasks'
import UserManagement from '../Views/UserManagement/UserManagement'
import { hasPermission } from '../Components/PermissionHandler'
import { Permission } from '../Types/permission'
import { Forbidden } from '../Views/Common/Forbidden'
import { NotFound } from '../Views/Common/NotFound'
import Employees from '../Views/Employees/Employees'
import Employee from '../Views/Employees/Employee'
import BillSync from '../Views/Settings/BillSync'
import Bills from '../Views/Bills/Bills'
import Bill from '../Views/Bills/Bill'
import Departments from '../Views/Departments/Departments'
import Department from '../Views/Departments/Department'
import Dashboard from '../Views/Dashboard/Dashboard'
import DataDictionary from '../Views/Settings/DataDictionary'
import AlertEmails from '../Views/AlertEmails/AlertEmails'
import Projects from '../Views/Projects/Projects'
import Project from '../Views/Projects/Project'
import SuccessEmail from '../Views/AlertEmails/Components/Success'
import ErrorEmail from '../Views/AlertEmails/Components/Error'
import Locations from '../Views/Locations/Locations'
import Location from '../Views/Locations/Location'

export enum ROUTE_PATH {
  BASE = '/',
  DASHBOARD = '/dashboard',
  VENDORS = '/vendors',
  VENDOR = '/vendor/:id',
  LOGIN = '/login',
  USER_MANAGEMENT = '/user-management',
  PURCHASE_ORDERS = '/purchase-orders',
  PURCHASE_ORDER = '/purchase-order/:id',
  SCHEDULED_TASKS = '/scheduled-tasks',
  EMPLOYEES = '/employees',
  EMPLOYEE = '/employee/:id',
  BILL_SYNC = '/settings/bill-sync',
  BILLS = '/bills',
  BILL = '/bill/:id',
  DEPARTMENTS = '/departments',
  DEPARTMENT = '/departments/:id',
  DATA_DICTIONARY = '/data-dictionary',
  ALERT_EMAILS = '/alert-emails',
  SUCCESS_EMAIL = '/alert-emails/success',
  ERROR_EMAIL = 'alert-emails/error',
  PROJECTS = '/projects',
  PROJECT = '/projects/:id',
  LOCATIONS = '/locations',
  LOCATION = '/locations/:id'
}

const AppRouter = () => {

  const auth = useContext(AuthContext)

  const AuthGateHandler = () =>
    <Conditional
      condition={!!auth.auth}
      onTrue={ () => <Forbidden/> }
      onFalse={ () => <Login/> }
    />

  return (
    composeAppRoutingProvider({
      routes: [
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth,
            onNotAllow: {
              render: <Login />
            },
          },
          paths: ['*'],
          render: <NotFound/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth,
            onNotAllow: {
              render: <Login/>
            },
          },
          paths: [ROUTE_PATH.VENDORS],
          render: <Vendors/>,
        },
        {
          kind:RouteType.PRIVATE,
          gate:  {
            allow:  !!auth.auth,
            onNotAllow: {
              render: <Login />
            },
          },
          paths: [ROUTE_PATH.SCHEDULED_TASKS],
          render: <ScheduledTasks />,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth,
            onNotAllow: {
              render: <Login/>
            },
          },
          paths: [ROUTE_PATH.VENDOR],
          render: <Vendor/>,
        },
        {
          kind: RouteType.PUBLIC,
          paths: [ROUTE_PATH.LOGIN],
          render: <Login/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth && hasPermission(auth.auth.user, Permission.CREATE_USERS),
            onNotAllow: {
              render: <AuthGateHandler/>,
            },
          },
          paths: [ROUTE_PATH.USER_MANAGEMENT],
          render: <UserManagement/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth && hasPermission(auth.auth.user, Permission.VIEW_EMPLOYEES),
            onNotAllow: {
              render: <AuthGateHandler/>,
            },
          },
          paths: [ROUTE_PATH.EMPLOYEES],
          render: <Employees/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth && hasPermission(auth.auth.user, Permission.VIEW_EMPLOYEES),
            onNotAllow: {
              render: <AuthGateHandler/>,
            },
          },
          paths: [ROUTE_PATH.EMPLOYEE],
          render: <Employee/>,
        },
        {
          kind:RouteType.PUBLIC,
          paths:['/'],
          render: <Login/>
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth && hasPermission(auth.auth.user, Permission.VIEW_BILL_SYNC),
            onNotAllow: {
              render: <AuthGateHandler/>
            },
          },
          paths: [ROUTE_PATH.BILL_SYNC],
          render: <BillSync/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth && hasPermission(auth.auth.user, Permission.VIEW_BILLS),
            onNotAllow: {
              render: <AuthGateHandler/>
            },
          },
          paths: [ROUTE_PATH.BILLS],
          render: <Bills/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth && hasPermission(auth.auth.user, Permission.VIEW_BILLS),
            onNotAllow: {
              render: <AuthGateHandler/>
            },
          },
          paths: [ROUTE_PATH.BILL],
          render: <Bill/>,
        },
        {
            kind: RouteType.PRIVATE,
            gate: {
              allow: !!auth.auth && hasPermission(auth.auth.user, Permission.VIEW_DEPARTMENTS),
              onNotAllow: {
                render: <AuthGateHandler/>
              },
            },
            paths: [ROUTE_PATH.DEPARTMENTS],
            render: <Departments />,
        },
        {
            kind: RouteType.PRIVATE,
            gate: {
              allow: !!auth.auth && hasPermission(auth.auth.user, Permission.VIEW_DEPARTMENTS),
              onNotAllow: {
                render: <AuthGateHandler/>
              },
            },
            paths: [ROUTE_PATH.DEPARTMENT],
            render: <Department />,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth,
            onNotAllow: {
              render: <AuthGateHandler/>
            },
          },
          paths: [ROUTE_PATH.DASHBOARD],
          render: <Dashboard/>,
      },
      {
            kind: RouteType.PRIVATE,
            gate: {
                allow: !!auth.auth,
                onNotAllow: {
                    render: <AuthGateHandler/>
                },
            },
            paths: [ROUTE_PATH.DATA_DICTIONARY],
            render: <DataDictionary/>,
        },
        {
            kind: RouteType.PRIVATE,
            gate: {
                allow: !!auth.auth,
                onNotAllow: {
                    render: <AuthGateHandler/>
                },
            },
            paths: [ROUTE_PATH.ALERT_EMAILS],
            render: <AlertEmails/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth,
            onNotAllow: {
              render: <AuthGateHandler/>,
            },
          },
          paths: [ROUTE_PATH.PROJECTS],
          render: <Projects/>,
        },
        {
          kind: RouteType.PRIVATE,
          gate: {
            allow: !!auth.auth,
            onNotAllow: {
              render: <Login/>
            },
          },
          paths: [ROUTE_PATH.PROJECT],
          render: <Project/>,
        },
        {
            kind: RouteType.PRIVATE,
            gate: {
              allow: !!auth.auth,
              onNotAllow: {
                render: <Login/>
              },
            },
            paths: [ROUTE_PATH.LOCATIONS],
            render: <Locations />,
        },
        {
            kind: RouteType.PRIVATE,
            gate: {
              allow: !!auth.auth,
              onNotAllow: {
                render: <Login/>
              },
            },
            paths: [ROUTE_PATH.LOCATION],
            render: <Location />,
        },
        {
            kind: RouteType.PRIVATE,
            gate: {
              allow: !!auth.auth,
              onNotAllow: {
                render: <Login/>
              },
            },
            paths: [ROUTE_PATH.SUCCESS_EMAIL],
            render: <SuccessEmail />,
        },
        {
            kind: RouteType.PRIVATE,
            gate: {
              allow: !!auth.auth,
              onNotAllow: {
                render: <Login/>
              },
            },
            paths: [ROUTE_PATH.ERROR_EMAIL],
            render: <ErrorEmail />,
        },
        {
          kind:RouteType.PUBLIC,
          paths:['/'],
          render: <Login/>
        }
      ]
    })
  )
}

export default AppRouter