import { DiscriminatedUnion, Union } from "kdg-react";

import { Job } from './job'
import { PaginatedResults } from './pagination'

export enum TLoadedCase {
  LOADING ="LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

export type TLoadedData<T> = DiscriminatedUnion<[
  Union<TLoadedCase.LOADING, TLoadedCase.LOADING>,
  Union<TLoadedCase.ERROR, string>,
  Union<TLoadedCase.LOADED,T>,
]>

export const defaultLoadedData: TLoadedData<any> = {
  case: TLoadedCase.LOADING,
  value: TLoadedCase.LOADING,
}

export type RecordWithHistory<T> = {
  Record:T,
  History:PaginatedResults<Job>
}

export type Option<A> = A | null

export interface RelatedItems {
  id: string,
  name: string,
  intacct_id: Option<string>,
  zoho_id: Option<string>
}

export type TNullable<T> = {
    [Property in keyof T]:T[Property] extends (boolean | any[]) ? T[Property] : (T[Property] | null)
  }
