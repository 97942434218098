import { ReactNode } from "react"

import Icon, { ICON_KIND } from './Icon'
import Tag from './Tag'

type Props<T> = {
    items:T[]
    onRemove:(_:T) => void
    parseLabel:(_:T) => ReactNode
}

const Tags = <T,>(props:Props<T>) => {
    return (
        <>
            {
                props.items.map(
                    (v,i) => (
                        <Tag key={ `tag-${i}` }>{ props.parseLabel(v) } <Icon kind={ ICON_KIND.Remove } onClick={ () => props.onRemove(v) }/></Tag>
                    )
                )
            }
        </>
    )
}

export default Tags
