import { ReactNode } from 'react'

type Props = {
  label:ReactNode
  value:ReactNode
}
const Field = (props:Props) => {
  return (
    <div className="data-row">
      <span className="data-label">{ props.label }</span>
      <span className="data-result">{ props.value || '-' }</span>
    </div>
  )
}

export default Field