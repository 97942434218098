import { RequestMethodArgs } from "kdg-react"
import { httpGet } from "./_common"
import { SettingField,Response,SettingFields } from '../Types/settings'


const mapFieldSetting = (fs: Response.SettingField): SettingField => ({
  id: fs.id,
  name: fs.name,
  intacctId: fs.intacctId || null,
  zohoId: fs.zohoId || null,
})

// const encodeStringOrNull = (str: string | null): string => {
//   return str ? encodeURIComponent(str) : '%00';
// }


export const getFieldSettings = async (
  args: RequestMethodArgs<Response.GetAllFieldSettings>,
  search: string | null,
  kind: SettingFields | null
) => {
  await httpGet({
    url: `/api/settings/GetAllFieldSettings`,
    success: args.success,
    errorHandler: args.errorHandler,
    mapResult: (fs: unknown) => (fs as Response.GetAllFieldSettings).map(mapFieldSetting),
    parameters:{
      search: search || '',
      kind: kind
    }
  })
}

