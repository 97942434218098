import { EntityConditional } from 'kdg-react'
import { SyncDirection } from '../Types/sync'
import Icon, { ICON_KIND } from './Icon'
import { Entities } from '../Types/job'

type Props = {
    direction: SyncDirection,
    entity?: Entities
}

const ModuleSyncDirection = (props:Props) => {
    return (
        <div className='d-flex justify-content-between align-items-center'>
            <h4 className='mb-0'>{ props.direction.from }</h4>
            <Icon size='xl' kind={ ICON_KIND.ArrowRight } />
            <h4 className='mb-0'>
                { props.direction.to }

                <EntityConditional
                    entity={ props.entity }
                    render={
                        (v) => <span className='text-muted'>: { v }</span>
                    }
                />
            </h4>
        </div>
    )
}

export default ModuleSyncDirection
