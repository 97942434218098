import React from 'react'
import { CTooltip } from '@coreui/react-pro'

type TooltipPlacement = 'top'|'left'
export type Triggers = 'hover' | 'focus' | 'click'
type Props =
{
  content:string
  placement?:TooltipPlacement
  trigger?:Triggers
}
export default class Tooltip extends React.PureComponent<Props>
{
  render()
  {
    return (
      <CTooltip trigger={this.props.trigger} content={ this.props.content } placement={ this.props.placement ? this.props.placement : 'top' }>
        {/* A wrapping element is required because of the way core UI determines the data attribute for the content */}
        <span>
          { this.props.children }
        </span>
      </CTooltip>
    )
  }
}