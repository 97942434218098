import { RequestMethodArgs, PostRequestMethodArgs } from "kdg-react"
import { AUTH_TOKEN_KEY, USER_ALIAS } from "../Context/AuthContext"
import { ROUTE_PATH } from "../Routing/Routing"
import { PermissionGroup } from "../Types/permission"
import { httpGet, httpPost } from "./_common"

export const handleLogout = async () => {
  await httpGet({
    url: '/auth/logout',
    success: () => {
      window.localStorage.removeItem(AUTH_TOKEN_KEY)
      window.localStorage.removeItem(USER_ALIAS)
      window.location.href = ROUTE_PATH.LOGIN
    },
    errorHandler:(e) => console.error('unable to logout', e)
  })
}

export const getAuthLoginUrl = async (args: RequestMethodArgs<string>) => {
  const loginUrl = await httpGet({
    url: '/auth/get-login-url',
    success: args.success,
    errorHandler: args.errorHandler,
  })
  return loginUrl;
}

type AuthTokenArgs = {
  code: string,
  alias: string | null,
}
export const getAuthToken = async (args: PostRequestMethodArgs<AuthTokenArgs, string>) => {
  await httpPost({
    url: `/auth/auth-token`,
    body: args.body,
    success: args.success,
    errorHandler: args.errorHandler,
  })
}

type refreshAuthTokenArgs = {
  refresh_token: string
  email: string,
  alias: string | null,
}
export const refreshAuthToken = async (args: PostRequestMethodArgs<refreshAuthTokenArgs, string>) => {
  await httpPost({
    url: `/auth/refresh-token`,
    body: args.body,
    success: args.success,
    errorHandler: args.errorHandler,
  })
}
export const getPermissionGroups = async (args:RequestMethodArgs<PermissionGroup[]>) => {
  await httpGet({
    url: '/auth/permission-groups',
    success: args.success,
    errorHandler: args.errorHandler,
  })
}