import { Card, DiscriminatedUnionHandler, Icon, Loader, Row, Table } from "kdg-react"
import BaseLayout from "../../Components/BaseLayout"
import {useEffect, useState } from "react"
import { DashJob, getSyncResults } from "../../Api/jobs"
import { TLoadedCase, TLoadedData, defaultLoadedData } from "../../Types/util"
import { getHoursSince } from "../../Util/datetime"

const Dashboard = () => {

    const [modules, setModules]=useState<TLoadedData<DashJob[]>>(defaultLoadedData)

    const fetch = async () => {
        await getSyncResults({
        success: (v) => {
        setModules({
            case: TLoadedCase.LOADED,
            value: v
        })
        }
    })
    }

    useEffect(()=>{
        fetch()
    },[])

    return (
        <BaseLayout 
            pageTitle={ 'Dashboard' }
        >
            <Row fluid>
                <Card
                    className="card-cover-height overflow-auto"
                    body={{
                    content: 
                        <>
                            <h6>Sync Results </h6>
                            <DiscriminatedUnionHandler
                                value={modules}
                                config={{
                                    [ TLoadedCase.LOADING ]: () => <Loader />,
                                    [ TLoadedCase.ERROR ]: e => e,
                                    [ TLoadedCase.LOADED ]: ({ value: v }) => (
                                    <Table
                                            headerClassName='table-light'
                                            className={'mt-3 table-hover row-border overflow-auto'}
                                            items={v}
                                            itemKey={x => x.id} 
                                            fields={{
                                                'Module': {
                                                    valueRender: x => x.module
                                                },
                                                'Total Records': {
                                                    valueRender: x => x.totalRecords
                                                },
                                                'Last Sync': {
                                                    valueRender: x => getHoursSince(x.lastSync)
                                                },
                                                'Sync Results': {
                                                    valueRender: x => (
                                                        <div>
                                                            <Icon
                                                                size='sm'
                                                                className="text-success"
                                                                icon={x => x.cilCheckAlt} /> {x.lastSyncResults.good}
                                                            <Icon
                                                                size='sm'
                                                                className="text-danger ms-3"
                                                                icon={x => x.cilWarning} />  {x.lastSyncResults.bad}

                                                        </div>
                                                    )
                                                },
                                            }}
                                        />    
                                    ),
                                }} 
                            />
                                
                        </>
                    }}               
                />
            </Row>
        </BaseLayout>
    )
} 

export default Dashboard