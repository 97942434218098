import React, { useState,useEffect,Fragment } from 'react'
import { Select,EntityConditional,DiscriminatedUnionHandler,Loader,DelayedTextInput,Card } from 'kdg-react'
import Table from '../../Components/TableWithNoData'

import BaseLayout from '../../Components/BaseLayout'
import { SettingField,SettingFields } from '../../Types/settings'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { getFieldSettings } from '../../Api/settings'


const options: SettingFields[] = [SettingFields.Accounts, SettingFields.PaymentTerms, SettingFields.Items]

const BillSync: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<SettingFields | null>(options[0]);
  const [items, setItems] = useState<TLoadedData<SettingField[]>>(defaultLoadedData)
  const [search, setSearch] = useState<string|null>(null);

  useEffect(() => {
    setItems(defaultLoadedData);
    getFieldSettings({
      success: (data: SettingField[]) => setItems({ case: TLoadedCase.LOADED, value: data }),
      errorHandler: (_error: any) => setItems({ case: TLoadedCase.ERROR, value: "unable to load settings" }),
    }, search, selectedOption);
  }, [selectedOption,search]);



  return (
    <BaseLayout pageTitle="Bill Sync Fields">
      <Card
        header={ {
          className:'d-flex align-items-center',
          content:(
            <Fragment>
              <div className="me-3">
                <Select
                  options={options}
                  parseKey={(option) => option}
                  parseOptionLabel={(option) => option}
                  value={selectedOption}
                  onChange={setSelectedOption}
                />
              </div>
              <div>
                <DelayedTextInput
                  placeholder="Search..."
                  delay={300}
                  value={search}
                  onDelay={setSearch}
                  className="me-3"
                />
              </div>
            </Fragment>
          )
        }}
        body={
          {
            content:(
              <EntityConditional
                entity={selectedOption !== null}
                render={
                  () => (
                    <DiscriminatedUnionHandler
                        value={ items }
                        config={ {
                          [TLoadedCase.LOADING]: () => <Loader />,
                          [TLoadedCase.ERROR]: e => e,
                          [TLoadedCase.LOADED]:({ value:v }) => (
                            <Table
                              noDataMessage={ 'There are no vendors to display.' }
                              headerClassName='table-light'
                              className={ 'table-hover row-border mb-0' }
                              items={ v }
                              itemKey={ (v) => v.id }
                              fields={
                                {
                                  'ID': { valueRender: (v) => v.id },
                                  'Name': { valueRender: (v) => v.name },
                                  'Zoho ID': { valueRender: (v) => v.zohoId || 'N/A' },
                                  'Intacct ID': { valueRender: (v) => v.intacctId || 'N/A' },
                                }
                              }
                            />
                          )
                        } }
                      />
                  )
                }
              />
            )
          }
        }
      />
    </BaseLayout>
  );
};

export default BillSync;



