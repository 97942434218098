import { useState,useEffect,useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { DiscriminatedUnionHandler,Loader,Row,Col,Card } from 'kdg-react'

import BaseLayout from '../../Components/BaseLayout'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { Employee } from '../../Types/employee'
import { getEmployee } from '../../Api/employees'
import DetailSection from '../../Components/DetailSection'
import Field from '../../Components/DetailField'
import { formatDate } from '../../Util/datetime'

const ViewEmployee = () => {
  const [employee, setEmployee] = useState<TLoadedData<Employee>>(defaultLoadedData)
  const params = useParams<{id:string}>()

  const handleLoadEmployee = useCallback(async () => {
    await getEmployee(params.id || 'not-found',{
      success:(v) => {
        setEmployee({ case:TLoadedCase.LOADED,value: v})
      },
      errorHandler:() => {
        setEmployee({ case:TLoadedCase.ERROR,value: 'could not load employee'})
      }
    })
  },[])

  useEffect(() => {
    handleLoadEmployee()
  }, [handleLoadEmployee])
  return (
    <BaseLayout
      pageTitle={ 'Employee' }
    >
      <DiscriminatedUnionHandler
        value={ employee }
        config={{
          [TLoadedCase.LOADING]: () => <Loader />,
          [TLoadedCase.ERROR]: e => e,
          [TLoadedCase.LOADED]: ({ value:v }) => {
            return (
              <Row fluid className='mb-5'>
                <Col md={8} lg={9}>
                  <Card
                    body={{
                      content:(
                        <div className={ 'd-flex align-items-center' }>
                          { v.name }
                        </div>
                      )
                    }}
                  />
                  <DetailSection title={ 'Info' }>
                    <Row>
                      <Col md={ 6 }>
                        <Field label={ 'Archived' } value={ v.archived } />
                        <Field label={ 'Cost Rate' } value={ v.costRate } />
                        <Field label={ 'Cost Rate Date' } value={ v.currentCostRateDate ? formatDate(v.currentCostRateDate) : null } />
                        <Field label={ 'Earning Type' } value={ v.earningType } />
                        <Field label={ 'Employment Status' } value={ v.employmentStatus } />
                        <Field label={ 'End Date' } value={ v.endDate ? formatDate(v.endDate) : null } />
                        <Field label={ 'Hire Date' } value={ v.hireDate ? formatDate(v.hireDate) : null } />
                      </Col>
                      <Col md={ 6 }>
                        <Field label={ 'Intacct ID' } value={ v.intacctId } />
                        <Field label={ 'IT Employee Status' } value={ v.itEmployeeStatus } />
                        <Field label={ 'Job Title' } value={ v.jobTitle } />
                        <Field label={ 'Position' } value={ v.position } />
                        <Field label={ 'Salary' } value={ v.salary } />
                        <Field label={ 'Supervisor Email' } value={ v.supervisorEmail } />
                        <Field label={ 'Email' } value={ v.workEmail } />
                      </Col>
                    </Row>
                  </DetailSection>
                  <DetailSection title={ 'Personal' }>
                    <Row>
                      <Col md={ 6 }>
                        <Field label={ 'First Name' } value={ v.firstName } />
                        <Field label={ 'Middle Initial' } value={ v.middleInitial } />
                        <Field label={ 'Last Name' } value={ v.lastName } />
                      </Col>
                      <Col md={ 6 }>
                        <Field label={ 'Email' }      value={ v.personalEmail } />
                        <Field label={ 'Phone' }      value={ v.personalPhone } />
                        <Field label={ 'Birth Day' }  value={ v.birthDate ? formatDate(v.birthDate) : null} />
                      </Col>
                    </Row>
                  </DetailSection>
                  <DetailSection title={ 'Department' }>
                    <Field label={ 'name' } value={ v.department.name } />
                  </DetailSection>
                </Col>
              </Row>
            )
          }
        }}
      />
    </BaseLayout>
  )
}

export default ViewEmployee
