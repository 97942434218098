import { useState } from 'react'
import { Clickable, Col, Conditional, Row } from "kdg-react"
import Modal from '../../../Components/Modal'
import Error from './Error'
import Success from './Success'
import ErrorPreview from '../Previews/error-preview.png'
import SuccessPreview from '../Previews/success-preview.png'


type Props = {
    className?:string
}
enum Show {
    Success='success',
    Error='error'
}

const Preview = (props:Props) =>  {
    const [showModal,setShowModal] = useState(false)
    const [shown,setShown] = useState(Show.Success)
    return (
        <>
            <Clickable onClick={ () => setShowModal(true) }  className={ props.className }>Preview</Clickable>
            <Conditional
                condition={ showModal }
                onTrue={
                    () => (
                        <Modal
                            size={ 'xl' }
                            header={ () => 'Email Preview' }
                            className='preview-modal'
                            onClose={ () => setShowModal(false) }
                            content={ () => (
                                <Row> 
                                    <Col md={ 3 }>
                                            <div className='email-preview' onClick={ () => setShown(Show.Success) } >
                                                <div className={ shown == Show.Success ? 'selected-border' : '' }>
                                                    <img src={ SuccessPreview } onClick={ () => setShown(Show.Success) } ></img>
                                                </div>
                                                <p>Success</p>
                                            </div>
                                            <div className='email-preview' onClick={ () => setShown(Show.Error) } >
                                                <div className={ shown == Show.Error ? 'selected-border' : '' }>
                                                    <img src={ ErrorPreview } onClick={ () => setShown(Show.Error) } ></img>
                                                </div>
                                                <p>Error</p>
                                            </div>
                                    </Col>
                                    <Col md={ 9 }>
                                        <div>
                                            <Conditional
                                                condition={ shown == Show.Success }
                                                onTrue={
                                                    () => <Success />
                                                }
                                                onFalse={
                                                    () => <Error />
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            ) }
                        />
                    )
                }
            />
        </>
    )
}

export default Preview
