import { LocalDate } from '@js-joda/core'
import { SyncStatus } from './sync'

export enum SortableFields {
    ProjectName="Project Name",
    ProjectNumber="Project Number",
    Status="Status",
}

export type Department = {
  id: string
  zohoId: string
  name: string
}

export type ProjectMeta = {
  id:string
  projectName:string
  projectNumber:string
  status:string
  syncStatus:SyncStatus|null
}

export type Project = {
  id:string
  projectName:string
  projectNumber:string
  startDate:LocalDate | null
  endDate:LocalDate | null
  details:string
  zohoId:string
  SMSIQuoteNumber:string|null
  projectType:string|null
  status:string|null
  customerPO:string|null
  projectCategory:string|null
  customer: string | null
  department: Department
  parentProject: ProjectMeta
  syncError:string|null
}