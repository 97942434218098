import { useState,useEffect,Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { DiscriminatedUnionHandler,Loader,Row,Col,EntityConditional,Card,ActionButton } from 'kdg-react'

import BaseLayout from '../../Components/BaseLayout'
import { defaultLoadedData,TLoadedCase,TLoadedData } from '../../Types/util'
import { fetchLocation } from '../../Api/locations'
import { Location as TLocation } from '../../Types/location'
import Icon, { ICON_KIND } from '../../Components/Icon'
import Field from '../../Components/DetailField'

const Location = () => {
    const params = useParams<{ id:string }>()
    const [location,setLocation] = useState<TLoadedData<TLocation>>(defaultLoadedData)
    const fetch = async () => {
        await fetchLocation(params.id || '',{
          success:(v) => {
            setLocation({
                case: TLoadedCase.LOADED,
                value: v,
            })
          },
          errorHandler:() => {
            setLocation({
                case: TLoadedCase.ERROR,
                value: 'Unable to load Location',
            })
          }
        })
       }

      useEffect(() => {
        fetch()
      },[params.id])
    return (
        <BaseLayout pageTitle={ 'Location' }>
            <DiscriminatedUnionHandler
                value={ location }
                config={{
                    [TLoadedCase.LOADING]: () => <Loader />,
                    [TLoadedCase.ERROR]: e => e.value,
                    [TLoadedCase.LOADED]: ({ value:v }) => {
                    return (
                        <Row fluid className={ 'mb-5' }>
                            <Col md={8} lg={9}>
                                <EntityConditional
                                    entity={ v.syncError }
                                    render={
                                    (v) => (
                                        <div className="alert alert-danger show d-flex justify-content-between align-items-center" role="alert">
                                        <h3>
                                            <Icon className='me-2' kind={ ICON_KIND.Ban } />
                                            Incoming Sync Error: { v }
                                        </h3>
                                        </div>
                                    )
                                    }
                                />
                                <Card
                                    body={ {
                                        content:
                                        <div className="d-flex justify-content-between">
                                            <div className='d-flex'>
                                            <ActionButton
                                                className='btn-light me-3'
                                                onClick={ () => history.back() }
                                            >
                                                <Icon kind={ICON_KIND.ArrowLeft}/>
                                            </ActionButton>
                                            <div>
                                                <div className="d-flex align-items-center">
                                                <h1>{ v.name }</h1>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    } }
                                />
                                <h6 className={ 'mt-4' }>Project</h6>
                                <Card
                                    body={ {
                                    content:
                                    <Fragment>
                                        <Row>
                                            <Col md={6}>
                                                <Field label={ 'Zoho Id' } value={ v.zohoId } />
                                            </Col>
                                            <Col md={6}>
                                                <Field label={ 'Intacct Id' } value={ v.intacctId } />
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    } }
                                />
                            </Col>
                        </Row>
                    ) }
                } }
            />
        </BaseLayout>
    )
}

export default Location
