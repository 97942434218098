
import Checkboxes from '../CheckBoxes'

export enum SyncStatuses {
    SUCCESSFUL='Successful',
    ERROR='Errors',
}

type Props = {
    value:SyncStatuses[]
    onChange:(_:SyncStatuses[]) => void
}

const SyncStatusCheckboxes = (props:Props) => {
    return (
        <Checkboxes
            value={ props.value }
            onChange={ props.onChange }
            label={ 'Sync Status:' }
            options={ [SyncStatuses.SUCCESSFUL,SyncStatuses.ERROR] }
            className={ 'inline-filter' }
            parseOptionLabel={ (v) => v }
        />
    )
}

export default SyncStatusCheckboxes
