import { useEffect,useCallback,useState } from "react"
import { Card,DelayedTextInput,DiscriminatedUnionHandler,Loader,Pagination,Select } from 'kdg-react'

import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import BaseLayout from "../../Components/BaseLayout"
import { usePagination } from "../../Hooks/hooks"
import { fetchAlertEmails } from "../../Api/alertEmails"
import { AlertEmail } from "../../Types/alertEmail"
import Table from "../../Components/TableWithNoData"
import { paginationOptions } from '../../Types/pagination'
import { CreateAlertEmail } from "./Components/CreateAlertEmail"
import EditAlertEmail from "./Components/EditAlertEmail"
import { JobKinds } from '../../Types/job'
import Preview from "./Components/Preview"

const AlertEmails = () => {
    const [search,setSearch] = useState('')
    const [alerts, setAlerts] = useState<TLoadedData<AlertEmail[]>>(defaultLoadedData)
    const [total,setTotal] = useState(0)
    const [jobName,setJobName] = useState<JobKinds|null>(null)
    const paginator = usePagination({ numberOfItemsPerPage:10,page:1 })

    const fetch = useCallback(async () => {
        setAlerts(defaultLoadedData)
        await fetchAlertEmails(paginator.pagination,search,jobName,{
            success:(items) => {
                setTotal(items.count)
                setAlerts({ case: TLoadedCase.LOADED, value:items.results })
             },
             errorHandler:() => {
                setAlerts({ case: TLoadedCase.ERROR, value:'unable to load alert emails' })
             }
        })
    },[search,paginator.pagination.numberOfItemsPerPage,paginator.pagination.page,jobName])

    useEffect(() => { fetch() },[fetch])
    return (
        <BaseLayout pageTitle={ 'Alert Emails' }>
            <Card
                header={ {
                className:'d-flex align-items-center',
                content:
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex">
                            <div className="d-flex align-items-center me-3">
                                <DelayedTextInput
                                    placeholder="Search..."
                                    value={ search }
                                    delay={ 300 }
                                    onDelay={
                                        (v) => {
                                            setSearch(v || "")
                                            paginator.reset()
                                        }
                                    }
                                />
                            </div>
                            <Select
                                placeholder='Select a Hangfire Job'
                                parseKey={ (v) => v }
                                parseOptionLabel={ (v) => v }
                                options={
                                    Object.values(JobKinds).filter((item) => {
                                        return isNaN(Number(item));
                                    })
                                }
                                value={ jobName }
                                onChange={ (v) => setJobName(v) }
                            />
                        </div>
                        <div>
                            <Preview className={ 'me-3' }/>
                            <CreateAlertEmail onSave={ () => fetch() } />
                        </div>
                    </div>
                } }
                body={ {
                content:<DiscriminatedUnionHandler
                    value={ alerts }
                    config={ {
                    [TLoadedCase.LOADING]: () => <Loader />,
                    [TLoadedCase.ERROR]: e => e,
                    [TLoadedCase.LOADED]:({ value:v }) => (
                        <Table
                            noDataMessage={ 'There are no bills to display.' }
                            footer={
                                <Pagination pagination={ paginator.pagination } totalRecords={ total } pageOptions={ paginationOptions } onChange={ paginator.update } optionsDirection="top"/>
                            }
                            headerClassName='table-light'
                            className={ 'table-hover row-border mb-0' }
                            items={ v }
                            itemKey={ (v) => v.id }
                            fields={
                                {
                                    'Hangfire Job Name':{ valueRender:(v) => v.job },
                                    'Email Name':{ valueRender:(v) => v.name },
                                    'Email Recipients':{ valueRender:(v) => v.emails.map((x) => x.email).join(', ') },
                                    'Actions':{ valueRender:(v) => <EditAlertEmail alertEmail={ v }  onUpdate={ () => fetch() } />, hideHeader:true },
                                }
                            }
                        />
                    )
                    } }
                />
                } }
            />
        </BaseLayout>
    )
}

export default AlertEmails