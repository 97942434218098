import { RequestMethodArgs,TPagination } from "kdg-react"
import { httpGet, httpPost } from "./_common"

import { Job,parseJobKinds,parseRecordJobKind,RecordJob,JobKinds } from '../Types/job'
import { parseInstant } from '../Util/datetime'
import { PaginatedResults } from '../Types/pagination'

export enum FetchJobKind {
  VENDORS="Vendors",
  PURCHASE_ORDERS="Purchase Orders",
  BILLS="Bills",
  DEPARTMENTS="Departments",
  PROJECTS="Projects",
  LOCATIONS='Locations',
}

type JobResponse = {
  id:string
  name: string
  start: string
  finish: string
  error:string|null
  created:number
  updated:number
  errors:number
}

export type RecordJobResponse = {
  jobId:string
  error:string|null
  jobName:string
  kind:string
  occurred:string
  recordId:string
}

type JobServerResponse<T> = {
  paginationData:PaginatedResults<T>
  results:{
    success:number
    failed:number
  }
}

export const mapJob = (x:JobResponse) : Job => ({
  id: x.id,
  name: parseJobKinds(x.name),
  start: parseInstant(x.start),
  finish: parseInstant(x.finish),
  error: x.error,
  created:x.created,
  updated:x.updated,
  errors:x.errors,
})

export const mapRecordJob = (x:RecordJobResponse) : RecordJob => ({
  jobId:x.jobId,
  error:x.error,
  jobName:parseJobKinds(x.jobName),
  kind:parseRecordJobKind(x.kind),
  occurred:parseInstant(x.occurred),
  recordId:x.recordId,
})

export const getJobs = async (jobs:JobKinds[],kind:FetchJobKind,paginator:TPagination,args:RequestMethodArgs<JobServerResponse<Job>>) => {
  httpPost({
    ...args,
    url:`/api/jobs`,
    body:{
      limit:paginator.numberOfItemsPerPage.toString(),
      offset:paginator.numberOfItemsPerPage * (paginator.page - 1),
      jobs:jobs,
      kind:kind,
    },
    mapResult: (x) => {
      const temp = x as JobServerResponse<JobResponse>
      return {
        results:temp.results,
        paginationData:{
          count:temp.paginationData.count,
          results:temp.paginationData.results.map(mapJob)
        }
      }
    }
  })
}

type DashJobResponse = {
  id:string
  name:string
  module:string
  start:string
  errors:number
  success:number
}

type SyncResults = {
  good:number
  bad:number
}

export type DashJob = {
  id:string
  name:string
  module:string
  totalRecords:number
  lastSync:string
  lastSyncResults: SyncResults
}

export const mapDashJob = (x:DashJobResponse) : DashJob => ({
  id: x.id,
  name: x.name,
  module: x.module,
  totalRecords: x.errors + x.success,
  lastSync:x.start,
  lastSyncResults: {
    good: x.success,
    bad: x.errors
  },
})

export const getSyncResults = async (args: RequestMethodArgs<DashJob[]>) => {
  await httpGet({
    url: '/api/dashboard',
    success: args.success,
    errorHandler: args.errorHandler,
    parameters:{

    },
    mapResult: (v:any) => ( v.map(mapDashJob))
  })
}

