import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DiscriminatedUnionHandler, Loader, Row, Col, Card, ActionButton, EntityConditional, Conditional, Table } from 'kdg-react';

import Tooltip from '../../Components/Tooltip';
import BaseLayout from '../../Components/BaseLayout';
import Icon, { ICON_KIND } from '../../Components/Icon';
import { fetchBill } from '../../Api/bills';
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util';
import { formatDate } from '../../Util/datetime';
import { Bill } from '../../Types/bills';
import DetailSection from '../../Components/DetailSection';

const BillView = () => {
  const params = useParams<{ id:string }>();
  const [bill, setBill] = useState<TLoadedData<Bill>>(defaultLoadedData);

  const fetch = async () => {
    await fetchBill(params.id || '',{
      success:(b) => {
        setBill({
            case: TLoadedCase.LOADED,
            value: b,
        });
      },
      errorHandler:() => {
        setBill({
            case: TLoadedCase.ERROR,
            value: 'unable to load Bill',
        });
      }
    });
  };
  useEffect(() => {
    fetch();
  },[params.id]);

  return (
    <BaseLayout
      pageTitle={ 'Bill ' }
    >
    <DiscriminatedUnionHandler
      value={bill}
      config={{
        [TLoadedCase.LOADING]: () => <Loader />,
        [TLoadedCase.ERROR]: e => e,
        [TLoadedCase.LOADED]: ({ value:b }) => {
          return (
            <Row fluid className={ 'mb-5' }>
              <Col md={8} lg={9}>
                <EntityConditional
                  entity={ b.status }
                  render={
                    (b) => (
                      <div className="alert alert-danger show d-flex justify-content-between align-items-center" role="alert">
                        <h3>
                          <Icon className='me-2' kind={ ICON_KIND.Ban } />
                          Incoming Sync Error: { b }
                        </h3>
                        {/*<button className='btn btn-danger'>Review and Resolve</button>*/}
                      </div>
                    )
                  }
                />
                <Card
                  body={ {
                    content:
                      <div className="d-flex justify-content-between">
                        <div className='d-flex'>
                          <ActionButton
                            className='btn-light me-3'
                            onClick={ () => history.back() }
                          >
                            <Icon kind={ICON_KIND.ArrowLeft}/>
                          </ActionButton>
                          <div>
                            <div className="d-flex align-items-center">
                              <h1>{ b.invoiceNumber }</h1>
                            </div>
                            <h4 className='text-muted'>{  b.transactionDate  ? formatDate(b.transactionDate) : null }</h4>
                          </div>
                        </div>
                        <div>
                          <div>
                            <Tooltip content={ "Not Implemented" }>
                              <a
                                href={``}
                                target={'_blank'}
                              >View in Intacct</a>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    } }
                />
                {/* <EntityConditional
                  entity={ b.vendor }
                  render={
                    (vendor) => (
                      <Fragment>
                        <h6 className={ 'mt-4' }>Vendor</h6>
                        <Card
                          body={ {
                            content:
                            <Fragment>
                              <Field label={ 'name' } value={ vendor.name } />
                              <Field label={ 'Intacct ID' } value={ vendor.intacctId } />
                              <Field label={ 'Zoho ID' } value={ vendor.zohoId } />
                            </Fragment>
                          } }
                        />
                      </Fragment>
                    )
                  }
                /> */}
                <Conditional
                  condition={ !!b.lineItems.length }
                  onTrue={
                    () => (
                      <DetailSection
                        title={ 'Line Items' }
                      >
                        <Table
                          items={ b.lineItems }
                          itemKey={ (b) => b.id }
                          fields={ {
                            'Account':{ valueRender:(b) => b.account ? b.account.name : '' },
                            'Item':{ valueRender:(b) => b.item ? b.item.name : '' },
                            'Location':{ valueRender:(b) => b.location ? b.location.name : '' },
                            'Total':{ valueRender:(b) => b.total ? b.total.toString() : '' },
                          } }
                        />
                      </DetailSection>
                    )
                  }
                />
              </Col>
            </Row>
          )
        }
      }}
    />
    </BaseLayout>
  )
}

export default BillView;
