
export const paginationOptions = {
  5:5,
  10:10,
  20:20,
  50:50,
  100:100,
}

export type PaginatedResults<T> = {
  results:T[],
  count:number
}