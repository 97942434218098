import { Badge, Enums } from "kdg-react";
import { VendorStatus } from "../../../Types/vendor";

type Props = {
  status: VendorStatus
  className?:string
}

export const VendorStatusBadge = (props: Props) => {
  const classNameMap = {
    [VendorStatus.ACTIVE]: Enums.Color.Success,
    [VendorStatus.INACTIVE]: Enums.Color.Secondary,
    [VendorStatus.SEE_ACCOUNTING]: Enums.Color.Warning,
    [VendorStatus.DO_NOT_USE]: Enums.Color.Danger,
  } satisfies { [Property in VendorStatus]: Enums.Color }

  return (
    <Badge
      color={classNameMap[props.status]}
      className={props.className}
    >
      {props.status}
    </Badge>
  )
}