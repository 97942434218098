import { Alert, AsyncButton, Col, Conditional, EntityConditional, Loader, Row, TextInput } from "kdg-react"
import { useCallback, useContext, useEffect, useState } from "react"
import { getAuthLoginUrl, getAuthToken } from "../../Api/auth"
import { AuthContext, AuthContextDispatcher, AUTH_TOKEN_KEY, tryParseAuthFromToken, USER_ALIAS } from "../../Context/AuthContext"
import { useAppNavigation, useAppNavigationQueryParams } from "../../Hooks/hooks"
import { ROUTE_PATH } from "../../Routing/Routing"

import zohoLogo from "../../assets/img/zoho-logo.png"
import logo from '../../assets/img/logo.png'

const Login = () => {
    const localAlias = window.localStorage.getItem(USER_ALIAS)
  const [loading, setLoading] = useState(true)
  const [loginUrl,setLoginUrl] = useState<string|null>(null)
  const [error,seterror] = useState<string|null>(null)
  const [showAlias,setShowAlias] = useState(!!localAlias)
  const [alias,setAlias] = useState(localAlias ? localAlias : '')
  const [params] = useAppNavigationQueryParams()
  const auth = useContext(AuthContext)
  const authDispatch = useContext(AuthContextDispatcher)
  const navigate = useAppNavigation()

  const loadLoginUrl = useCallback(() => {
    setLoading(true)
    try {
      getAuthLoginUrl({
        success: url => {
          console.log(url)
          setLoginUrl(url)
        },
        errorHandler: () => 'Unable to intialize login'
      })
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!loginUrl) {
      loadLoginUrl()
    }
  }, [])

  const handleAuth = useCallback((code: string) => {
    setLoading(true)
    try {
      getAuthToken({
        body: {
          alias: window.localStorage.getItem(USER_ALIAS) || null,
          code,
        },
        success: (token) => {
          window.localStorage.setItem(AUTH_TOKEN_KEY, token)
          authDispatch(tryParseAuthFromToken(token))
        },
        errorHandler: (response) => {
          console.error('unable to login', response)
          seterror('Unable to login')
        }
      })
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (params.get('code')) {
      handleAuth(params.get('code')!)
    }
  }, [params, handleAuth])

  useEffect(() => {
    if (auth.auth) {
      navigate(ROUTE_PATH.DASHBOARD)
    }
  },[auth])

  const updateAlias = (alias:string) => {
    window.localStorage.setItem(USER_ALIAS, alias)
    setAlias(alias)
  }

  return (
    <div className="login-bg">
      <Row className="justify-content-center">
        <Col md={10}>
          {/* How to add code to card body in KDG react compon?? */}
          <div className="card">
            <div className="card-body p-0 d-flex flex-column">
              <Row className="w-100">
                <Col md={4} className="px-4 h-100 d-flex flex-column justify-content-center text-center">
                <img src={ logo } width="160px" alt="SMSI" className="mb-4 mx-auto"></img>
                  <Conditional
                    condition={loading}
                    onTrue={() => <Loader />}
                    onFalse={() => (
                      <EntityConditional
                        entity={loginUrl}
                        render={url => (
                          <>
                            <a href={url}>
                              <AsyncButton
                                className="logo-button"
                                loading={loading}
                                onClick={() => { }}
                                disabled={loading}
                              >
                                <img src={ zohoLogo }  alt="Zoho Logo"></img>
                                Login with Zoho
                              </AsyncButton>
                            </a>
                            <p className="invalid-feedback" style={{ display:'block' }}>{ error }</p>
                            <div className="my-4">
                              <a href="" className="text-muted small" onClick={ (e) => { e.preventDefault(); setShowAlias(!showAlias) } }>Using an alias?</a>
                              <Conditional
                                condition={ showAlias }
                                onTrue={
                                  () =>
                                    <TextInput
                                      className="mt-3"
                                      placeholder={'Alias'}
                                      value={ alias }
                                      onChange={ updateAlias }
                                    />
                                }
                              />
                              
                            </div>
                          </>
                        )}
                        fallback={() => (
                          <Conditional
                            condition={!!error}
                            onTrue={() => (
                              <Alert message={error}/>
                            )}
                          />
                        )}
                      />
                    )}
                  />
                  <p className="small text-muted my-3">
                  Lorem ipsum dolor sit amet,.
                  </p>
                </Col>
                <Col className="img-wrapper">
                </Col>
              </Row>
            </div>
          </div>
        
        </Col>
      </Row>
    </div>
  )
}

export default Login