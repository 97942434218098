import React from 'react'
import CIcon from '@coreui/icons-react'
import * as icons from '@coreui/icons-pro'

const ICON_KIND_LOOKUP = {
  'Edit'         : icons.cilNote,
  'Trash'        : icons.cilTrash,
  'Archive'      : icons.cilArchive,
  'Copy'         : icons.cilCopy,
  'Restore'      : icons.cilRestore,
  'Collapsed'    : icons.cilCaretTop,
  'Expanded': icons.cilCaretBottom,
  'CollapseAll': icons.cilVerticalAlignCenter,
  'ExpandAll': icons.cilResizeHeight,
  'ArrowRight'   : icons.cilArrowRight,
  'ArrowLeft'    : icons.cilArrowLeft,
  'Check': icons.cisCheck,
  'Undo': icons.cilActionUndo,
  'Swap'         : icons.cilSwapHorizontal,
  'Add'          : icons.cilPlus,
  'Remove'       : icons.cilX,
  'Smile'        : icons.cilSmile,
  'Doc'          : icons.cilNotes,
  'AddItem'      : icons.cilCartPlus,
  'Print'        : icons.cilPrinter,
  'Note'         : icons.cilStickyNote,
  'Lock'         : icons.cisLockLocked,
  'Download'     : icons.cilCloudDownload,
  'Invoice'      : icons.cisTags,
  'QuickInfo'    : icons.cisGauge,
  'Calendar'     : icons.cilCalendar,
  'Ban'          : icons.cilBan,
  'History'      : icons.cilHistory,
  'Sticky'       : icons.cibPinboard,
  'View'         : icons.cilMagnifyingGlass,
  'FolderShare'  : icons.cilFolderShared,
  'FolderPlus'  : icons.cilFolderPlus,
  'Phone'  : icons.cilPhone,
  'Flag'   : icons.cilFlag,
  'Time'   : icons.cilClock,
  'Move'   : icons.cilMove,
  'Building'   : icons.cilBuilding,
  'Email': icons.cilMail,
  'Group': icons.cilGroup,
  'User': icons.cilUser,
  'Upload': icons.cilCloudUpload,
  'Warning': icons.cilWarning,
  'VisibilityTrue': icons.cilEye,
  'VisibilityFalse': icons.cilEyeSlash,
  'Gear': icons.cilCog,
  'Handshake': icons.cilHandshake,
  'ChevronRight': icons.cilChevronRightAlt,
  'ChevronLeft': icons.cilChevronLeftAlt,
  'Fire': icons.cilFire,
  'Money': icons.cilMoney,
  'SpreadSheet': icons.cilSpreadsheet,
  'Institution': icons.cilInstitution,
  'Sync': icons.cilSync,
  'Filter': icons.cilListFilter,
}

export const ICON_KIND =
  Object.keys(ICON_KIND_LOOKUP).reduce((agg,x) =>
    ({ ...agg,[x]:x }),{}
  ) as { [key in keyof typeof ICON_KIND_LOOKUP]:keyof typeof ICON_KIND_LOOKUP }

type Props = {
  kind:keyof typeof ICON_KIND
  className?: string
  name?: string
  size?: 'sm'|'lg'|'xl'
  title?: string
  use?: string
  onClick?:(_:React.MouseEvent<any>) => void
}

export default class Icon extends React.PureComponent<Props> {
  render() {
    return (
      <CIcon
        { ...{ ...this.props,kind:undefined } }
        icon={ ICON_KIND_LOOKUP[this.props.kind] }
        className={
          ([] as string[]).concat(
            this.props.className ? [this.props.className] : [],
            this.props.onClick ? ['clickable-icon'] : []
          ).join(' ')
        }
      />
    )
  }
}
