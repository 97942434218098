import { Loader,Col,Row,Card,DelayedTextInput,DiscriminatedUnionHandler,Pagination,useNotification,Enums,SortOrder } from 'kdg-react'
import { useEffect,useState,useCallback } from 'react'
import BaseLayout from "../../Components/BaseLayout"
import { getVendors,syncNow } from '../../Api/vendors'
import { Filter, VendorMeta, defaultFilter,SortableFields } from '../../Types/vendor'

import Icon, { ICON_KIND } from '../../Components/Icon'
import Sync from './Components/Sync'
import { useAppNavigation, usePagination } from "../../Hooks/hooks"
import { instantToDate } from '../../Util/datetime'
import { defaultLoadedData, TLoadedCase, TLoadedData } from '../../Types/util'
import { Entities } from '../../Types/job'
import { SyncStatus } from '../../Types/sync'
import { paginationOptions } from '../../Types/pagination'
import Tags from '../../Components/Tags'


import Table from '../../Components/TableWithNoData'
import ControlledModal from '../../Components/ControlledModal'
import SyncButton from '../../Components/Buttons/Sync'
import SyncStatusCheckboxes, { SyncStatuses } from '../../Components/Form/SyncStatusCheckboxes'
import { useTableSort,defaultSort } from '../../Hooks/sort'

const initialSort = {
    field:SortableFields.VendorName,
    sort:defaultSort,
    sortOrder:SortOrder.ASC,
}

const Vendors = () => {
  const [search,setSearch] = useState('')
  const paginator = usePagination({ numberOfItemsPerPage:10,page:1 })
  const [total,setTotal] = useState(0)
  const [filter,setFilter] = useState<Filter>(defaultFilter)
  const navigate = useAppNavigation()

  const [status,setStatus] = useState<SyncStatuses[]>([SyncStatuses.ERROR,SyncStatuses.SUCCESSFUL])

  const [vendors, setVendors] = useState<TLoadedData<VendorMeta[]>>(defaultLoadedData)
  const { notify } = useNotification()

    const triggerSync = () => {
        syncNow({
            success:(_) => {
                notify({
                    key:new Date().getUTCMilliseconds(),
                    message:'Sync Successfully Triggered',
                    type:Enums.Color.Success,
                    autoDismiss:3000
                })
            }
        })
    }

    const { tableSort, setTableSort,mappedSortFields } = useTableSort<SortableFields,VendorMeta>({
        mapping:{
            [SortableFields.VendorName]:'name',
            [SortableFields.SMSIAccountNumber]:'account_number',
            [SortableFields.VendorStatus]:'status',
            [SortableFields.LastUpdated]:'last_updated',
            [SortableFields.Errors]:'errors',
        },
        initialState:initialSort,
    })

    const parseTableField = (x:string):SortableFields => {
        switch (x) {
            case SortableFields.VendorName:
            case SortableFields.SMSIAccountNumber:
            case SortableFields.VendorStatus:
            case SortableFields.LastUpdated:
            case SortableFields.Errors:
                return x
          default:
                throw new Error(`unable to parse table field: ${x}`)
        }
      }

  const fetch = useCallback(async () => {
    setVendors(defaultLoadedData)
    getVendors(search,'',status,filter,paginator.pagination, mappedSortFields, {
      success:(v) => {
        setVendors({ case:TLoadedCase.LOADED,value:v.results })
        setTotal(v.count)
      },
      errorHandler:(error) => {
        setVendors({ case:TLoadedCase.ERROR,value:'cannot load vendors' })
        console.error('error',error)
      }
    } )
  },[search,paginator.pagination.numberOfItemsPerPage,paginator.pagination.page,status,filter.status,filter.linked,mappedSortFields.fieldName,mappedSortFields.order])

  useEffect(() => {
    fetch()
  },[fetch])
  return (
    <BaseLayout pageTitle={ 'Vendors' }>
      <Row fluid className='d-flex h-100 flex-fill'>
        <Col md={8} lg={9} className='position-relative mb-3'>
          <div className="card overflow-card mb-2">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-3">
                  <DelayedTextInput
                    placeholder="Search Vendor, Account Number..."
                    value={ search }
                    delay={ 300 }
                    onDelay={
                      (v) => {
                        setSearch(v || "")
                        paginator.reset()
                      }
                    }
                  />
                </div>
                <SyncStatusCheckboxes
                    value={ status }
                    onChange={ (v) => { setStatus(v); paginator.reset() }}
                />
                <ControlledModal filter={ filter } onChange={ (v)=>{ setFilter(v); paginator.reset() } }/>
              </div>
              <div className='d-flex justify-content-end align-items-center'>
                <SyncButton onClick={ triggerSync }/>
              </div>
            </div>
            <div className="card-body">
              <div className='filters-active'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                  <div className='d-flex flex-row align-items-center flex-wrap filter-results'>
                    <span className='filter-label'>Filters:&nbsp;</span>
                    <Tags
                        items={ filter.linked.map((v) => (v as string)).concat(filter.status.map((v) => (v as string))) }
                        onRemove={ (v) => { setFilter({ status:filter.status.filter((x) => x != v),linked:filter.linked.filter((x) => x != v) }); paginator.reset() } }
                        parseLabel={ (v) => v }
                    />
                  </div>
                </div>
              </div>
              <DiscriminatedUnionHandler
                value={ vendors }
                config={ {
                  [TLoadedCase.LOADING]: () => <Loader />,
                  [TLoadedCase.ERROR]: e => e.value,
                  [TLoadedCase.LOADED]:({ value:v }) => (
                    <Table
                      noDataMessage={ 'There are no vendors to display.' }
                      headerClassName='table-light'
                      className={ 'table-hover row-border mb-0' }
                      items={ v }
                      itemKey={ (v) => v.id }
                      onSort={
                        (v) => setTableSort({
                            field:parseTableField(v.field.toString()),
                            sort:v.sort.sort,
                            sortOrder:v.sort.order,
                        })
                        }
                        defaultSort={
                            {
                                field:tableSort.field,
                                sort:{
                                    sort:tableSort.sort,
                                    order:tableSort.sortOrder,
                                }
                            }
                        }
                      extractRowClassname={ (v) => v.syncStatus == SyncStatus.ERROR ? 'error' : 'success' }
                      fields={
                        {
                          [SortableFields.VendorName]:{ valueRender:(v) => v.name,sort:{ sort:defaultSort, order:SortOrder.ASC } },
                          [SortableFields.SMSIAccountNumber]:{ valueRender:(v) => v.account_number,sort:{ sort:defaultSort, order:SortOrder.ASC } },
                          [SortableFields.VendorStatus]:{ valueRender:(v) => v.status,sort:{ sort:defaultSort, order:SortOrder.ASC } },
                          [SortableFields.LastUpdated]:{ valueRender:(v) => instantToDate(v.lastUpdated),sort:{ sort:defaultSort, order:SortOrder.ASC } },
                          [SortableFields.Errors]:{ valueRender:(v:VendorMeta) => v.error,sort:{ sort:defaultSort, order:SortOrder.ASC } },
                          'link':{ hideHeader:true,valueRender:(v) => <Icon kind={ ICON_KIND.ArrowRight } onClick={ () => navigate(`/vendor/${v.id}`) }/> },
                          //...(!vendorKinds.errors ? {} : { 'Errors':{ valueRender:(v:VendorMeta) => v.error } }),
                          //'Errors': vendorKinds.errors ? { valueRender:(v) => v.error } : undefined
                        }
                      }
                    />

                  )
                } }
              />
            </div>
            <Pagination pagination={ paginator.pagination } totalRecords={ total } onChange={ paginator.update } pageOptions={ paginationOptions } optionsDirection='top'/>
          </div>

        </Col>
        <Col md={4} lg={3}>
        <Card
            className='mb-2'
            body={{
              className: '',
              content:
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='mb-0'>Creator</h4>
                <Icon size='xl' kind={ ICON_KIND.ArrowRight } />
                <h4 className='mb-0'>Intacct<span className='text-muted'>: { Entities.TOP_LEVEL }</span></h4>
              </div>
            }}
          />
        <Sync />
        </Col>
      </Row>
    </BaseLayout>
  )
}

export default Vendors
