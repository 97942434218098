import { Card } from 'kdg-react'

const SyncStats = (props: { amount: number, kind: 'success' | 'error' }) => {
  const isSuccess = props.kind == 'success'
  return (
    <Card
      className={`bg-${ isSuccess ? 'success' : 'danger'} mb-2 p-0`}
      body={{
        className: '',
        content:
          <div className="d-flex align-items-center">
            <h2 className="text-white me-2">{props.amount}</h2>
            <h5 className="text-white">{ isSuccess ? 'Successful' : 'Errors'}</h5>
          </div>
      }}
    />
  )
}

export default SyncStats
