import { ReactNode } from 'react'
import Image from '../assets/img/no-results.png'

type Props = {
  message:ReactNode
}

const NoData = (props:Props) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center bg-light p-5'>
      <img src={ Image } alt="No results" width={'70px'} />
      <h2 className="my-2">No Results</h2>
      <p className="mb-2">{ props.message }</p>
    </div>
  )
}

export default NoData
