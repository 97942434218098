import { Conditional, EntityConditional } from "kdg-react"
import { useContext } from "react"
import { AuthContext } from "../Context/AuthContext"
import { Permission } from "../Types/permission"
import { User } from "../Types/user"

export const hasPermission = (user: User, permission: Permission) => 
  user.permissions.includes(permission)

type Props = {
  permission: Permission
  hasPermission:() => React.ReactNode
  fallback?:() => React.ReactNode
}

export const PermissionHandler = (props: Props) => {
  const auth = useContext(AuthContext)

  return (
    <EntityConditional
      entity={auth.auth}
      render={ a =>
        <Conditional
          condition={hasPermission(a.user, props.permission)}
          onTrue={() => props.hasPermission()}
          onFalse={() => props.fallback ? props.fallback() : null}
        />
      }
    />
  )

}