import { ActionButton } from "kdg-react";
import BaseLayout from "../../Components/BaseLayout"
import { useAppNavigation } from "../../Hooks/hooks"
import { ROUTE_PATH } from "../../Routing/Routing";

export const Forbidden = () => {

  const navigate = useAppNavigation();

  return (
    <BaseLayout
      pageTitle={'403 Forbidden'}
    >
      <div className="d-flex align-items-center flex-column mt-4">
        <img className="mb-4" src={ '/src/assets/img/error-illustration.png' } width="450px" />
        <h1 className="mb-2 display-1">403 Forbidden</h1>
        <p className="mb-2">You do not have access to the requested resource</p>
        <ActionButton
          onClick={() => navigate(ROUTE_PATH.BASE)}
          className={'my-2'}
        >
          Go Home
        </ActionButton>
      </div>

    </BaseLayout>
  )
}